import gql from 'graphql-tag';

export const DataGrid = gql`
query DataGrid($id: ID!){
DataGrid(id:$id) {
  nestedComponentType
  subPortals {
    configObjectId
    name
    configObjectType
    portalType
    archived
    favorite
    modalRequired
    subPortalType
    portalDataSharing
    tabGroup
    order
    accessibilityRegex
    portalCards {
      editabilityRegex
      configObjectId
      name
      configObjectType
      label
      reloadRequired
      archived
      favorite
      x
      y
      w
      h
      order
      isStatic
      type
      uiComponentId
      isAccessibile
      accessibilityRegex
      referenceData
      initialComponent
      isFullScreenRequired
      privileges {
        privilegeId
        privilegeType
        itemId
        roleId
        createdBy
        isDeleted
        insert_ts
        updatedBy
        update_ts
        deletionDate
      }
    }
  }
  dataGrid{
    configObjectId
    defaultOrdering
    gridType
    isHavingAdvanceFilterForm
    nestedComponentType
    swimlaneRequired
    modalRequired
    isRowReOrder
    isBorderEnable
        isStrippedEnable
        isHoverEnable
        isScrollEnable
        isServerPaginationEnable
        isRowSelectionEnable
        isHeaderAvailable
        isSubComponentEnable
        subComponentType
        isEditButtonEnable
        defaultSorting
        gridDataAlignment
        gridHeaderAlignment
    privileges {
      privilegeId
      privilegeType
      itemId
      roleId
      createdBy
      isDeleted
      insert_ts
      updatedBy
      update_ts
      deletionDate
    }
    name
    configObjectType
    projectId
    itemDescription
    buttonPanels {
      configObjectId
      defaultType
      buttonPanelPosition
      mode
      privileges {
        privilegeId
        privilegeType
        itemId
        roleId
        createdBy
        isDeleted
        insert_ts
        updatedBy
        update_ts
        deletionDate
      }
      name
      configObjectType
      itemDescription
      projectId
       menuButtons{
                  defaultType 
                  menuLabel 
                  position
                  buttonClass
                  warningMessage
                  order
                  accessibilityRegex
                  editabilityRegex
                  expressionAvailable
                  transactionName
                  modalRequired
                  portalId
                  configObjectId
                  name
                  configObjectType
                  childRelations {
                          relationType
                          parentItemId
                          childItemId
                          }
                  privileges {
                          privilegeId
                          privilegeType
                          itemId
                          roleId
                          }
                  buttons{
                          label
                          order
                          buttonClass
                          warningMessage
                          portalId
                          buttonAlignment
                          accessibilityRegex
                          editabilityRegex
                          expressionAvailable
                          configObjectId
                          name
                          configObjectType
                          projectId
                          modalCss
                          modalRequired
                          transactionName
                          childRelations {
                          relationType
                          parentItemId
                          childItemId
                          }
                          privileges {
                          privilegeId
                          privilegeType
                          itemId
                          roleId
                          }
                      }
                     
                  }
      buttons {
        label
        order
        buttonClass
        dbCode
        toolTip
        buttonStyle
        icon
        portalId
        modalRequired
        buttonAlignment
        modalCss
        transactionName
        privileges {
          privilegeId
          privilegeType
          itemId
          roleId
          createdBy
          isDeleted
          insert_ts
          updatedBy
          update_ts
          deletionDate
        }
        accessibilityRegex
        editabilityRegex
        expressionAvailable
        configObjectId
        name
        configObjectType
        projectId
      }
    }
    form {
      configObjectId
      expressionAvailable
      accessibilityRegex
      editabilityRegex
      logicalEntity {
        name
        configObjectId
        configObjectType
        createdBy
        isDeleted
        itemDescription
        insert_ts
        projectId
        updatedBy
        update_ts
        deletionDate
        dbTypeName
        supportedFlavor
        generateSkeleton
        logicalColumns {
          isPrimaryKey
          isDisplayColumn
          dataType
          isVerticalFieldKey
          dbCode
          length
          dbType
          isMandatory
          jsonName
          mode
          isUnique
          isDerived
          configObjectId
          name
          configObjectType
          createdBy
          isDeleted
          itemDescription
          insert_ts
          projectId
          updatedBy
          update_ts
          deletionDate
        }
      }
      privileges {
        privilegeId
        privilegeType
        itemId
        roleId
        createdBy
        isDeleted
        insert_ts
        updatedBy
        update_ts
        deletionDate
      }
    }
    dataGridColumns {
      headerName
      order
      hyperlinkDBCode
      modalCss
      visible
      dateFormat
      configObjectId
      name
      configObjectType
      accessibilityRegex
      dbCode
      filterEnable
      filterType
      columnType
      isDisplayDetail
      isPrimaryKey
      editabilityRegex
      expressionAvailable
      itemDescription
      projectId
      toolTip
      hyperLink
      hrefValue
      actionColumn
      actionColumnType
      group
      icon
      width
      isEllipsesEnable
      isDefaultEditable
      isShowOnlyOnEdit
      isAuditColumnJson
      collapseIcon
      auditColumnName
      columnDataAlignment
      columnHeaderAlignment
      isTimeStamp
      datasourceId
      multivalueList
      selectItemsReferenceID
      privileges {
        privilegeId
        privilegeType
        itemId
        roleId
        createdBy
        isDeleted
        insert_ts
        updatedBy
        update_ts
        deletionDate
      }
      logicalColumn {
          isPrimaryKey
          isDisplayColumn
          dataType
          isVerticalFieldKey
          dbCode
          length
          dbType
          isMandatory
          jsonName
          mode
          isUnique
          isDerived
          configObjectId
          name
          configObjectType
          createdBy
          isDeleted
          itemDescription
          insert_ts
          projectId
          updatedBy
          update_ts
          deletionDate
          standardValidations {
                  name
                  configObjectId
                  configObjectType
                  mode
                  defaultErrorMessage
                  validationType
                  regex
                  isConditionAvailable
                  conditionExpression
                  conditionFields
                  description
                }
        }
      formField {
        label
        order
        height
        editorType
        type
        displayType
        isMandatory
        isButtonTextBox
        expressionAvailable
        refreshFormOnChange
        accessibilityRegex
        editabilityRegex
        expressionFieldString
        configObjectId
        name
        configObjectType
        createdBy
        isDeleted
        itemDescription
        insert_ts
        projectId
        updatedBy
        update_ts
        deletionDate
        multivalueList
        selectItemsReferenceID
        isAsyncOption
        isClearable
        placeHolder
        dataSourceName
        defaultValue
        isMultiLingual
        formatDate
        isApplyDateFormat
        resetOnRefresh
        rowspan
        colspan
        hasToolTipIcon
        toolTipText
        isSearchBoxRequired
        isHyperlink
        gotoLink
        uploadLabel
        outDbcodeUploadFileTempId
        outDbcodeUploadFileName
        outDbcodeUploadFileType
        isRefreshFormWithUploadData
        valueType
        isReadOnly
        starCount
        starColor
        mask
        maskChar
        isSearchable
        isDisabled
        isMulti
        showYearDropdown
        showMonthDropdown
        maxDate
        minDate
        dateFormat
        dropdownMode
        timeFormat
        showTimeSelect
        showTimeSelectOnly
        timeIntervals
        timeCaption
        entityName
        parentDBCode
        layout
        dbCode
        privileges {
          privilegeId
          privilegeType
          itemId
          roleId
          createdBy
          isDeleted
          insert_ts
          updatedBy
          update_ts
          deletionDate
        }
        logicalColumn {
          isPrimaryKey
          isDisplayColumn
          dataType
          isVerticalFieldKey
          dbCode
          length
          dbType
          isMandatory
          jsonName
          mode
          isUnique
          isDerived
          configObjectId
          name
          configObjectType
          createdBy
          isDeleted
          itemDescription
          insert_ts
          projectId
          updatedBy
          update_ts
          deletionDate
        }
        buttons {
            label
            order
            type
            dbCode
            toolTip
            buttonStyle
            icon
            modalRequired
            componentId
            buttonClass
            portalId
            buttonAlignment
            accessibilityRegex
            editabilityRegex
            expressionAvailable
            configObjectId
            name
            configObjectType
            projectId
            modalCss
            transactionName
            childRelations {
              relationType
              parentItemId
              childItemId
            }
            privileges {
              privilegeId
              privilegeType
              itemId
              roleId
            }
          }
        fontSize
        hasSupportingLabel
        hasValueInSupportingLabel
        supportingLabel
        supportingLabelFontSize
        labelColor
      }
    }
    actionDataGridColumns {
      headerName
      order
      hrefValue
      visible
      configObjectId
      name
      configObjectType
      projectId
      toolTip
      actionColumn
      actionColumnType
      privileges {
        privilegeId
        privilegeType
        itemId
        roleId
        createdBy
        isDeleted
        insert_ts
        updatedBy
        update_ts
        deletionDate
      }
      group
      icon
      width
    }
  }
  
    configObjectId
    defaultOrdering
    label
    gridType
    isHavingAdvanceFilterForm
    swimlaneRequired
    modalRequired
    isRowReOrder
    isBorderEnable
        isStrippedEnable
        isHoverEnable
        isScrollEnable
        isServerPaginationEnable
        isRowSelectionEnable
        isHeaderAvailable
        isSubComponentEnable
        subComponentType
        isEditButtonEnable
        defaultSorting
        gridDataAlignment
        gridHeaderAlignment
    privileges {
      privilegeId
      privilegeType
      itemId
      roleId
      createdBy
      isDeleted
      insert_ts
      updatedBy
      update_ts
      deletionDate
    }
    name
    configObjectType
    projectId
    itemDescription
    buttonPanels {
      configObjectId
      defaultType
      buttonPanelPosition
      mode
      privileges {
        privilegeId
        privilegeType
        itemId
        roleId
        createdBy
        isDeleted
        insert_ts
        updatedBy
        update_ts
        deletionDate
      }
      name
      configObjectType
      itemDescription
      projectId
       menuButtons{
                  defaultType 
                  menuLabel 
                  position
                  buttonClass
                  warningMessage
                  order
                  accessibilityRegex
                  editabilityRegex
                  expressionAvailable
                  transactionName
                  modalRequired
                  portalId
                  configObjectId
                  name
                  configObjectType
                  childRelations {
                          relationType
                          parentItemId
                          childItemId
                          }
                  privileges {
                          privilegeId
                          privilegeType
                          itemId
                          roleId
                          }
                  buttons{
                          label
                          order
                          buttonClass
                          warningMessage
                          portalId
                          buttonAlignment
                          accessibilityRegex
                          editabilityRegex
                          expressionAvailable
                          configObjectId
                          name
                          configObjectType
                          projectId
                          modalCss
                          modalRequired
                          transactionName
                          childRelations {
                          relationType
                          parentItemId
                          childItemId
                          }
                          privileges {
                          privilegeId
                          privilegeType
                          itemId
                          roleId
                          }
                      }
                     
                  }
      buttons {
        label
        modalRequired
        order
        type
        dbCode
        toolTip
        buttonStyle
        icon
        componentId
        buttonClass
        portalId
        buttonAlignment
        modalCss
        transactionName
        childRelations {
          relationType
          parentItemId
          childItemId
          }
        privileges {
          privilegeId
          privilegeType
          itemId
          roleId
          createdBy
          isDeleted
          insert_ts
          updatedBy
          update_ts
          deletionDate
        }    
        accessibilityRegex
        editabilityRegex
        expressionAvailable
        configObjectId
        name
        configObjectType
        projectId
      }
    }
    form {
      configObjectId
      expressionAvailable
      accessibilityRegex
      editabilityRegex
      logicalEntity {
        name
        configObjectId
        configObjectType
        createdBy
        isDeleted
        itemDescription
        insert_ts
        projectId
        updatedBy
        update_ts
        deletionDate
        dbTypeName
        supportedFlavor
        generateSkeleton
        logicalColumns {
          isPrimaryKey
          isDisplayColumn
          dataType
          isVerticalFieldKey
          dbCode
          length
          dbType
          isMandatory
          jsonName
          mode
          isUnique
          isDerived
          configObjectId
          name
          configObjectType
          createdBy
          isDeleted
          itemDescription
          insert_ts
          projectId
          updatedBy
          update_ts
          deletionDate
          standardValidations {
                  name
                  configObjectId
                  configObjectType
                  mode
                  defaultErrorMessage
                  validationType
                  regex
                  isConditionAvailable
                  conditionExpression
                  conditionFields
                  description
                }
        }
      }
      privileges {
        privilegeId
        privilegeType
        itemId
        roleId
        createdBy
        isDeleted
        insert_ts
        updatedBy
        update_ts
        deletionDate
      }
    }
    dataGridColumns {
      headerName
      order
      hyperlinkDBCode
      modalCss
      visible
      dateFormat
      configObjectId
      name
      configObjectType
      accessibilityRegex
      dbCode
      filterEnable
      filterType
      columnType
      isDisplayDetail
      isPrimaryKey
      editabilityRegex
      expressionAvailable
      itemDescription
      projectId
      toolTip
      hyperLink
      hrefValue
      actionColumn
      actionColumnType
      group
      icon
      width
      isEllipsesEnable
      isDefaultEditable
      isShowOnlyOnEdit
      isAuditColumnJson
      collapseIcon
      auditColumnName
      columnDataAlignment
      columnHeaderAlignment
      isTimeStamp
      datasourceId
      multivalueList
      selectItemsReferenceID
      privileges {
        privilegeId
        privilegeType
        itemId
        roleId
        createdBy
        isDeleted
        insert_ts
        updatedBy
        update_ts
        deletionDate
      }
      logicalColumn {
          isPrimaryKey
          isDisplayColumn
          dataType
          isVerticalFieldKey
          dbCode
          length
          dbType
          isMandatory
          jsonName
          mode
          isUnique
          isDerived
          configObjectId
          name
          configObjectType
          createdBy
          isDeleted
          itemDescription
          insert_ts
          projectId
          updatedBy
          update_ts
          deletionDate
        }
      formField {
        label
        order
        height
        editorType
        type
        displayType
        isMandatory
        isButtonTextBox
        expressionAvailable
        refreshFormOnChange
        accessibilityRegex
        editabilityRegex
        expressionFieldString
        configObjectId
        name
        configObjectType
        createdBy
        isDeleted
        itemDescription
        insert_ts
        projectId
        updatedBy
        update_ts
        deletionDate
        multivalueList
        selectItemsReferenceID
        isAsyncOption
        isClearable
        placeHolder
        dataSourceName
        defaultValue
        isMultiLingual
        formatDate
        isApplyDateFormat
        resetOnRefresh
        rowspan
        colspan
        hasToolTipIcon
        toolTipText
        isSearchBoxRequired
        isHyperlink
        gotoLink
        uploadLabel
        outDbcodeUploadFileTempId
        outDbcodeUploadFileName
        outDbcodeUploadFileType
        isRefreshFormWithUploadData
        valueType
        isReadOnly
        starCount
        starColor
        mask
        maskChar
        isSearchable
        isDisabled
        isMulti
        showYearDropdown
        showMonthDropdown
        maxDate
        minDate
        dateFormat
        dropdownMode
        timeFormat
        showTimeSelect
        showTimeSelectOnly
        timeIntervals
        timeCaption
        entityName
        parentDBCode
        layout
        dbCode
        privileges {
          privilegeId
          privilegeType
          itemId
          roleId
          createdBy
          isDeleted
          insert_ts
          updatedBy
          update_ts
          deletionDate
        }
        logicalColumn {
          isPrimaryKey
          isDisplayColumn
          dataType
          isVerticalFieldKey
          dbCode
          length
          dbType
          isMandatory
          jsonName
          mode
          isUnique
          isDerived
          configObjectId
          name
          configObjectType
          createdBy
          isDeleted
          itemDescription
          insert_ts
          projectId
          updatedBy
          update_ts
          deletionDate
        }
        buttons {
            label
            order
            type
            dbCode
            toolTip
            buttonStyle
            icon
            modalRequired
            componentId
            buttonClass
            warningMessage
            portalId
            buttonAlignment
            accessibilityRegex
            editabilityRegex
            expressionAvailable
            configObjectId
            name
            configObjectType
            projectId
            modalCss
            transactionName
            childRelations {
              relationType
              parentItemId
              childItemId
            }
            privileges {
              privilegeId
              privilegeType
              itemId
              roleId
            }
          }
        fontSize
        hasSupportingLabel
        hasValueInSupportingLabel
        supportingLabel
        supportingLabelFontSize
        labelColor
      }
    }
    actionDataGridColumns {
      headerName
      order
      hrefValue
      visible
      configObjectId
      name
      configObjectType
      projectId
      toolTip
      actionColumn
      actionColumnType
      privileges {
        privilegeId
        privilegeType
        itemId
        roleId
        createdBy
        isDeleted
        insert_ts
        updatedBy
        update_ts
        deletionDate
      }
      group
      icon
      width
    }
  }
}
  `