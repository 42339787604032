import InfoAuthConfig from './auth/info.auth.config';
import App from './components/App';
import ChangePassword from './components/ChangePassword';
import { AUTH } from './constants';
import { InfoAuth } from './auth';
import Authenticator from './components/Authenticator';
import ResetPassword from './components/ForgotPassword';
import SignUp from './components/SignUp';
import config from './config/index';
import RefreshToken from './refreshToken/index';
declare global {
  interface Window {
    require: any;
    env: any;
  }
}

const requiredummy = () => {
  console.log('abcd');
  return '123';
};
window.require = requiredummy;
export {
  App,
  Authenticator,
  SignUp,
  ResetPassword,
  InfoAuthConfig,
  AUTH,
  ChangePassword,
  InfoAuth,
  config,
  RefreshToken,
};
