import { client } from '../utils/ClientUtils';
import { CompositeEntityNode } from '../api/metadata-query/CompositeEntityNode';
import { MenuGroup } from '../api/metadata-query/MenuGroup';
import { CompositeEntity } from '../api/metadata-query/CompositeEntity';
import { RootNode } from '../api/metadata-query/RootNode';
import { ChildNodesInTree } from '../api/metadata-query/ChildNodesInTree';
import { ReportForm } from '../api/metadata-query/ReportForm';
import { Portal } from '../api/metadata-query/Portal';
import { PortalDataGrid } from '../api/metadata-query/PortalDataGrid';
import { Widget } from '../api/metadata-query/Widget';
import { PortalFilterForm } from '../api/metadata-query/PortalFilterForm';
import { PortalForm } from '../api/metadata-query/PortalForm';
import { FormQuery } from '../api/metadata-query/FormQuery';
import { userDetailsJwtKey } from '../api/index';
import { InfoAuth } from 'info-auth-client';
import { router } from '../routes/Routes';
import { createStorage } from '../storage';
import { AUTH_TOKEN } from '../constants/appeng.enum';
let graphqlquery: any;

if (window.env.REACT_APP_PLATFORM == 'desktop') {
}
export const authJwtKey = async () => {
  try {
    const storage = createStorage();
    const auth = storage.getItem(AUTH_TOKEN);
    return auth;
  } catch (e) {
    router.navigate('/');
    window.location.reload();
  }
};
export const getAllChildFormList = (
  childNodes: any,
  hasNodes: any,
  formType: any,
  mode: any
) => {
  let childList: object[] = [];
  if (hasNodes) {
    let childNodesList = childNodes ? [...childNodes] : [];
    childNodesList.sort((a: any, b: any) => (a.order > b.order ? 1 : -1));
    childNodesList.map((childNode: any) => {
      if (
        childNode.addToParentDisplay &&
        ((childNode.addToParentEditForm && mode === 'Edit') ||
          (childNode.addToParentInsertForm && mode === 'Insert'))
      ) {
        let childObject = {
          form:
            formType === 'Edit'
              ? childNode.editForm
                ? childNode.editForm
                : childNode.insertForm
              : childNode.insertForm
                ? childNode.insertForm
                : childNode.editForm,
          nodeName: childNode.displayNodeName,
          expressionAvailable: childNode.expressionAvailable,
          accessibilityRegex: childNode.accessibilityRegex,
          entityId: childNode.entity.configObjectId,
          entityName: childNode.entity.name,
          nodeId: childNode.configObjectId,
          gridId: childNode.dataGrid ? childNode.dataGrid.configObjectId : null,
          showGridOnly: childNode.showGrid && childNode.dataGrid ? true : false,
        };
        childList.push(childObject);
      }
      return childNodesList;
    });
  }
  return childList;
};

export const getDispalyColumn = (logicalColumns: any) => {
  return logicalColumns.find((lc: any) => lc.isDisplayColumn);
};

export const getPrimaryDBCode = (logicalColumns: any) => {
  return logicalColumns.find((lc: any) => lc.isPrimaryKey)
    ? logicalColumns.find((lc: any) => lc.isPrimaryKey).dbCode
    : undefined;
};

export const getPrimaryDBCodeExtractor = (
  compositeEntityNodeData: any,
  dataGrid?: any
) => {
  if (dataGrid) {
    return dataGrid.dataGridColumns.find((lc: any) => lc.isPrimaryKey)
      ? dataGrid.dataGridColumns.find((lc: any) => lc.isPrimaryKey)
          .logicalColumn
        ? dataGrid.dataGridColumns.find((lc: any) => lc.isPrimaryKey)
            .logicalColumn.dbCode
        : dataGrid.dataGridColumns.find((lc: any) => lc.isPrimaryKey).dbCode
      : getPrimaryDBCode(dataGrid.form.logicalEntity.logicalColumns);
  }
  if (compositeEntityNodeData.CompositeEntityNode) {
    return getPrimaryDBCode(
      compositeEntityNodeData.CompositeEntityNode.entity.logicalColumns
    );
  } else if (
    compositeEntityNodeData.DataGrid &&
    compositeEntityNodeData.DataGrid.form &&
    compositeEntityNodeData.DataGrid.form.logicalEntity
  ) {
    return getPrimaryDBCode(
      compositeEntityNodeData.DataGrid.form.logicalEntity.logicalColumns
    );
  } else if (
    compositeEntityNodeData.DataGrid &&
    compositeEntityNodeData.DataGrid.form &&
    compositeEntityNodeData.DataGrid.form.logicalEntity
  ) {
    return getPrimaryDBCode(
      compositeEntityNodeData.DataGrid.form.logicalEntity.logicalColumns
    );
  } else {
    return compositeEntityNodeData.DataGrid.dataGridColumns.find(
      (lc: any) => lc.isPrimaryKey
    )
      ? compositeEntityNodeData.DataGrid.dataGridColumns.find(
          (lc: any) => lc.isPrimaryKey
        ).logicalColumn
        ? compositeEntityNodeData.DataGrid.dataGridColumns.find(
            (lc: any) => lc.isPrimaryKey
          ).logicalColumn.dbCode
        : compositeEntityNodeData.DataGrid.dataGridColumns.find(
            (lc: any) => lc.isPrimaryKey
          ).dbCode
      : undefined;
  }
};

export const getButtonList = (buttonPanels: any, mode: any) => {
  const buttonList: any[] = [];
  buttonPanels.map((buttonPanel: any) => {
    const buttonMode = buttonPanel.mode === 'INSERT' ? 'Insert' : 'Edit';
    if (buttonMode === mode) {
      buttonPanel.buttons.map((button: any) => {
        buttonList.push(button);
        return null;
      });
    }
    return null;
  });
  return buttonList;
};

export const getForm = (mode: any, CompositeEntityNode: any) => {
  if (mode === 'Edit') {
    return CompositeEntityNode.editForm
      ? CompositeEntityNode.editForm
      : CompositeEntityNode.insertForm;
  } else {
    return CompositeEntityNode.insertForm
      ? CompositeEntityNode.insertForm
      : CompositeEntityNode.editForm;
  }
};

export const getConfigData = async (queryType: any, id: any) => {
  id = id.includes('_') ? id.split('_')[1] : id;
  const isServerEnv = window.env.REACT_APP_PLATFORM !== 'desktop';
  let query: any;
  switch (queryType) {
    case 'NodeQuery':
      query = CompositeEntityNode;
      break;
    case 'MenuQuery':
      query = MenuGroup;
      break;
    case 'CEQuery':
      query = CompositeEntity;
      break;
    case 'RootNodeQuery':
      query = RootNode;
      break;
    case 'ChildNodeInTreeQuery':
      query = ChildNodesInTree;
      break;
    case 'ReportFormQuery':
      query = ReportForm;
      break;
    case 'PortalQuery':
      query = Portal;
      break;
    case 'WidgetQuery':
      query = Widget;
      break;
    case 'PortalDataGridQuery':
      query = PortalDataGrid;
      break;
    case 'PortalFilterFormQuery':
      query = PortalFilterForm;
      break;
    case 'PortalFormQuery':
      query = PortalForm;
      break;
    case 'FormQuery':
      query = FormQuery;
      break;
  }
  if (isServerEnv) {
    const userDetails = await userDetailsJwtKey();
    const authToken = await authJwtKey();
    return await client.query({
      query: query,
      variables: { id: id },
      context: {
        headers: {
          authorization: authToken,
          userDetails: userDetails,
          provider: window.env.REACT_APP_AUTH_DETAILS!.provider.type,
        },
      },
    });
  } else {
    query = query.loc.source.body;
    query = query.trim();
    query = query.substring(query.indexOf('{'));
    query = query.substring(0, query.length);
    query = query.replace('$id', '"' + id + '"');
    return await graphqlquery(query);
  }
};

export const getFormId = (node: any, mode: any) => {
  return mode === 'Edit'
    ? node.editForm
      ? node.editForm.configObjectId
      : node.insertForm.configObjectId
    : node.insertForm
      ? node.insertForm.configObjectId
      : node.editForm.configObjectId;
};
