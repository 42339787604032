import gql from 'graphql-tag';

export const FormQuery = gql`
  query Form($id: ID!) {
    Form(id: $id) {
      isRepeatable
      filterFormReloadControl
      parentRelations {
        relationType
        parentItemId
        childItemId
      }
      logicalEntity {
        configObjectId
        name
        configObjectType
        logicalColumns {
          isPrimaryKey
          isDisplayColumn
          dataType
          isVerticalFieldKey
          dbCode
          length
          dbType
          isMandatory
          jsonName
          mode
          isUnique
          isDerived
          configObjectId
          name
          configObjectType
          projectId
          standardValidations {
            name
            configObjectId
            configObjectType
            mode
            defaultErrorMessage
            validationType
            regex
            isConditionAvailable
            conditionExpression
            conditionFields
            description
          }
        }
      }
      defaultDataString
      tabRequiredInFormsection
      columnDataPreprocessors {
        configObjectId
        name
        configObjectType
        preProcessorBean
        isMultiValue
        excecutionType
        jsCode
        order
        accessibilityRegex
        expressionAvailable
        actionFlows {
          configObjectId
          name
          configObjectType
        }
      }
      tab
      order
      formType
      buttonPanels {
        configObjectId
        defaultType
        buttonPanelPosition
        mode
        name
        projectId
        configObjectType
        privileges {
          privilegeId
          privilegeType
          itemId
          roleId
        }
        menuButtons {
          defaultType
          menuLabel
          position
          buttonClass
          warningMessage
          order
          accessibilityRegex
          editabilityRegex
          expressionAvailable
          transactionName
          modalRequired
          portalId
          configObjectId
          name
          configObjectType
          childRelations {
            relationType
            parentItemId
            childItemId
          }
          privileges {
            privilegeId
            privilegeType
            itemId
            roleId
          }
          buttons {
            label
            order
            buttonClass
            warningMessage
            portalId
            buttonAlignment
            accessibilityRegex
            editabilityRegex
            expressionAvailable
            configObjectId
            name
            configObjectType
            projectId
            modalCss
            modalRequired
            transactionName
            childRelations {
              relationType
              parentItemId
              childItemId
            }
            privileges {
              privilegeId
              privilegeType
              itemId
              roleId
            }
          }
        }
        buttons {
          label
          order
          buttonClass
          warningMessage
          portalId
          buttonAlignment
          accessibilityRegex
          editabilityRegex
          expressionAvailable
          configObjectId
          name
          configObjectType
          projectId
          modalCss
          modalRequired
          transactionName
          childRelations {
            relationType
            parentItemId
            childItemId
          }
          privileges {
            privilegeId
            privilegeType
            itemId
            roleId
          }
        }
      }
      privileges {
        privilegeId
        privilegeType
        itemId
        roleId
      }
      expressionAvailable
      accessibilityRegex
      editabilityRegex
      formLabel
      maxRepeatation
      defaultRepeatation
      repeatationStyle
      isDeletionAllowed
      configObjectId
      name
      configObjectType
      projectId
      formSections {
        configObjectId
        headerLabel
        order
        displayName
        accessibilityRegex
        editabilityRegex
        expressionAvailable
        tabGroup
        requiredFormfields
        componentsPerRow
        privileges {
          privilegeId
          privilegeType
          itemId
          roleId
        }
        name
        configObjectType
        projectId
        formFields {
          mandatoryIconAccessibilityRegex
          formatDate
          isApplyDateFormat
          entityName
          parentDBCode
          dbCode
          layout
          label
          label
          order
          defaultValue
          colspan
          columnDataPreprocessors {
            configObjectId
            name
            configObjectType
            preProcessorBean
            isMultiValue
            excecutionType
            jsCode
            order
            accessibilityRegex
            expressionAvailable
            actionFlows {
              configObjectId
              name
              configObjectType
            }
          }
          type
          editorType
          height
          multivalueList
          resetOnRefresh
          isMandatory
          selectItemsReferenceID
          isAsyncOption
          isMulti
          placeHolder
          expressionAvailable
          refreshFormOnChange
          accessibilityRegex
          editabilityRegex
          previewAccessibilityRegex
          previewEditabilityRegex
          deleteAccessibilityRegex
          deleteEditabilityRegex
          acceptedFileTypes
          maxFilesAllowed
          isReorderAllowed
          configObjectId
          showYearDropdown
          showMonthDropdown
          maxDate
          minDate
          dropdownMode
          timeFormat
          showTimeSelect
          showTimeSelectOnly
          timeCaption
          timeIntervals
          isClearable
          privileges {
            privilegeId
            privilegeType
            itemId
            roleId
          }
          name
          configObjectType
          itemDescription
          projectId
          logicalColumn {
            isPrimaryKey
            dataType
            isVerticalFieldKey
            dbCode
            length
            dbType
            isMandatory
            jsonName
            mode
            isUnique
            isDerived
            configObjectId
            name
            configObjectType
            projectId
            standardValidations {
              name
              configObjectId
              configObjectType
              mode
              defaultErrorMessage
              validationType
              regex
              isConditionAvailable
              conditionExpression
              conditionFields
              description
            }
          }
          buttons {
            label
            order
            type
            dbCode
            toolTip
            buttonStyle
            icon
            modalRequired
            componentId
            buttonClass
            warningMessage
            portalId
            buttonAlignment
            accessibilityRegex
            editabilityRegex
            expressionAvailable
            configObjectId
            name
            configObjectType
            projectId
            modalCss
            transactionName
            childRelations {
              relationType
              parentItemId
              childItemId
            }
            privileges {
              privilegeId
              privilegeType
              itemId
              roleId
            }
          }
          fontSize
          hasSupportingLabel
          hasValueInSupportingLabel
          supportingLabel
          supportingLabelFontSize
          labelColor
        }
      }
    }
  }
`;
