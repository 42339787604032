import { ApiError, ApiErrorResponse } from '../model/api.error';
import { v4 as uuid } from 'uuid';

let authService: any;
if (window.env.REACT_APP_PLATFORM === 'desktop') {
  // authService= require('info-auth').authService;
}
const isServerEnv = window.env.REACT_APP_PLATFORM != 'desktop';
const getRestConfig = (
  contentType: string,
  transactionId: string,
  authToken: string,
  provider?: string
) => {
  const authProvider: string = provider ? provider : 'infoauth';
  const contentTypeConfig = () => {
    switch (contentType ? contentType : '') {
      case 'multipart':
        return 'multipart/form-data;  boundary=----WebKitFormBoundarytyE4wkKlZ5CQJVTG';
      default:
        return 'application/json';
    }
  };

  const config: any = {
    headers: {
      mediaType: 'json',
      'Content-Type': contentTypeConfig(),
      aeTransactionId: transactionId,
      authorization: authToken,
      provider: authProvider,
    },
  };

  if (window.env.NODE_ENV === 'development') {
    config.headers['app-key'] = window.env.APIKEY;
  }

  return config;
};

const login = async (url: string, data: any) => {
  let result;
  const standardPostData = {
    client_id: 'kbyuFDidLLm280LIwVFiazOqjO3ty8KH',
    redirect_uri: 'https://openidconnect.net/callback',
    scope: 'openid profile email phone',
    response_type: 'id_token token',
    state: '4a3084576c3c48a36287120a675424fd8c4d94dc',
  };
  const mergedData = Object.assign(data, standardPostData);
  try {
    const config = getRestConfig('json', uuid(), '');
    result = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(mergedData),
      headers: config.headers,
    });
    if (!result.ok) {
      const errResponse = await result.json();
      const err: ApiError = new ApiError(errResponse.message, errResponse);
      throw err;
    }
    return result.json();
  } catch (e) {
    throw e;
  }
};
const apiDesktop = {
  async refresh(authServerUrl: any, data: any) {
    const url = authServerUrl + '/v1/refresh';
    return await login(url, data);
  },
  async signIn(url: any, data: any) {
    let result;
    try {
      result = await authService.desktopAuthorization(
        'openid profile email phone',
        'kbyuFDidLLm280LIwVFiazOqjO3ty8KH',
        data.userName,
        data.password
      );
      if (result.code == 403 || result.code == 422) {
        const errResponse = await result;
        const err: ApiError = new ApiError(errResponse.message, errResponse);
        throw err;
      }
      return result;
    } catch (e) {
      throw e;
    }
  },

  async createActivityTracking(
    activityTrackingUrl: string,
    data: any,
    authToken: string,
    provider: string
  ) {
    const url = activityTrackingUrl + '/v1/useractivitytrackings';
    try {
      const config = getRestConfig('json', uuid(), authToken, provider);
      const result = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: config.headers,
      });
      if (!result.ok) {
        const errResponse = await result.json();
        const err: ApiError = new ApiError(errResponse.message, errResponse);
        throw err;
      }
      return result.json();
    } catch (e) {
      throw e;
    }
  },

  async signUp(authServerUrl: any, user: any) {
    try {
      const config = getRestConfig('json', uuid(), '');
      const result = await fetch(authServerUrl + '/v1/signUpUserAuth', {
        method: 'POST',
        headers: config.headers,
        body: JSON.stringify(user),
      });
      if (!result.ok) {
        const errResponse = await result.json();
        const err: ApiError = new ApiError(errResponse.message, errResponse);
        throw err;
      }
      return result.json();
    } catch (e) {
      throw e;
    }
  },

  async signUpUserProfile(authServerUrl: any, user: any) {
    try {
      const config = getRestConfig('json', user.aeTransactionId, '');
      const result = await fetch(authServerUrl + '/v1/signUpUserProfile', {
        method: 'POST',
        headers: config.headers,
        body: JSON.stringify(user),
      });
      if (!result.ok) {
        const errResponse = await result.json();
        const err: ApiError = new ApiError(errResponse.message, errResponse);
        throw err;
      }
      return result.json();
    } catch (e) {
      throw e;
    }
  },

  async forgotPassword(authServerUrl: any, data: any) {
    let result: any;
    try {
      const config = getRestConfig('json', uuid(), '');
      result = await fetch(authServerUrl + '/v1/user/password/reset', {
        method: 'POST',
        headers: config.headers,
        body: JSON.stringify(data),
      });
      if (!result.ok) {
        const errResponse = await result.json();
        const err: ApiError = new ApiError(errResponse.message, errResponse);
        throw err;
      }
      return result.json();
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }
      throw new Error(result);
    }
  },
  async passwordReset(authServerUrl: any, data: any) {
    try {
      const config = getRestConfig('json', uuid(), '');
      const result = await fetch(authServerUrl + '/v1/user/password', {
        method: 'POST',
        headers: config.headers,
        body: JSON.stringify(data),
      });
      if (!result.ok) {
        const errResponse = await result.json();
        const err: ApiError = new ApiError(errResponse.message, errResponse);
        throw err;
      }
      return result;
    } catch (e) {
      throw e;
    }
  },
  async confirmSignUp(authServerUrl: any, data: any) {
    try {
      const config = getRestConfig('json', data.transactionId, '');
      const result = await fetch(authServerUrl + '/v1/confirm', {
        method: 'POST',
        headers: config.headers,
        body: JSON.stringify(data),
      });
      if (!result.ok) {
        const errResponse = await result.json();
        const err: ApiError = new ApiError(errResponse.message, errResponse);
        throw err;
      }
      return result;
    } catch (e) {
      throw e;
    }
  },

  async insertDefaultUserMapping(
    authServerUrl: string,
    userId: string,
    transactionId: string
  ) {
    try {
      const config = getRestConfig('json', transactionId, '');
      const result = await fetch(authServerUrl + '/v1/userMappings', {
        method: 'POST',
        headers: config.headers,
        body: JSON.stringify({ userId: userId }),
      });
      if (!result.ok) {
        const errResponse = await result.json();
        const err: ApiError = new ApiError(errResponse.message, errResponse);
        throw err;
      }
      return result;
    } catch (e) {
      throw e;
    }
  },

  async changePassword(authServerUrl: any, data: any) {
    let result;
    try {
      result = await authService.updatePassword(
        data.userName,
        data.oldPassword,
        data.newPassword
      );
      if (result.code == 400) {
        const errResponse = await result;
        const err: ApiError = new ApiError(errResponse.message, errResponse);
        throw err;
      }
      return result;
    } catch (e) {
      throw e;
    }
  },
  async resendVerificationCode(authServerUrl: any, data: any) {
    try {
      const config = getRestConfig('json', uuid(), '');
      const result = await fetch(authServerUrl + '/v1/user/code/resend', {
        method: 'PUT',
        headers: config.headers,
        body: JSON.stringify(data),
      });
      if (!result.ok) {
        const errResponse = await result.json();
        const err: ApiError = new ApiError(errResponse.message, errResponse);
        throw err;
      }
      return result.json();
    } catch (e) {
      throw e;
    }
  },
};
const apiServer = {
  async refresh(authServerUrl: any, data: any) {
    const url = authServerUrl + '/v1/refresh';
    return await login(url, data);
  },

  async signIn(authServerUrl: any, data: any) {
    const url = authServerUrl + '/v1/authorize';
    const postData = {
      user_name: data.userName,
      password: data.password,
    };
    return await login(url, postData);
  },

  async createActivityTracking(
    activityTrackingUrl: string,
    data: any,
    authToken: string,
    provider: string
  ) {
    const url = activityTrackingUrl + '/v1/useractivitytrackings';
    try {
      const config = getRestConfig('json', uuid(), authToken, provider);
      const result = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: config.headers,
      });
      if (!result.ok) {
        const errResponse = await result.json();
        const err: ApiError = new ApiError(errResponse.message, errResponse);
        throw err;
      }
      return result.json();
    } catch (e) {
      throw e;
    }
  },

  async signUp(authServerUrl: any, user: any) {
    try {
      const config = getRestConfig('json', uuid(), '');
      const result = await fetch(authServerUrl + '/v1/signUpUserAuth', {
        method: 'POST',
        headers: config.headers,
        body: JSON.stringify(user),
      });
      if (!result.ok) {
        const errResponse = await result.json();
        const err: ApiError = new ApiError(errResponse.message, errResponse);
        throw err;
      }
      return result.json();
    } catch (e) {
      throw e;
    }
  },

  async signUpUserProfile(authServerUrl: any, user: any) {
    try {
      const config = getRestConfig('json', user.aeTransactionId, '');
      const result = await fetch(authServerUrl + '/v1/signUpUserProfile', {
        method: 'POST',
        headers: config.headers,
        body: JSON.stringify(user),
      });
      if (!result.ok) {
        const errResponse = await result.json();
        const err: ApiError = new ApiError(errResponse.message, errResponse);
        throw err;
      }
      return result.json();
    } catch (e) {
      throw e;
    }
  },

  async forgotPassword(authServerUrl: any, data: any) {
    let result: any;
    try {
      const config = getRestConfig('json', uuid(), '');
      result = await fetch(authServerUrl + '/v1/user/password/reset', {
        method: 'POST',
        headers: config.headers,
        body: JSON.stringify(data),
      });
      if (!result.ok) {
        const errResponse = await result.json();
        const err: ApiError = new ApiError(errResponse.message, errResponse);
        throw err;
      }
      return result.json();
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }
      throw new Error(result);
    }
  },
  async passwordReset(authServerUrl: any, data: any) {
    try {
      const config = getRestConfig('json', uuid(), '');
      const result = await fetch(authServerUrl + '/v1/user/password', {
        method: 'POST',
        headers: config.headers,
        body: JSON.stringify(data),
      });
      if (!result.ok) {
        const errResponse = await result.json();
        const err: ApiError = new ApiError(errResponse.message, errResponse);
        throw err;
      }
      return result;
    } catch (e) {
      throw e;
    }
  },
  async confirmSignUp(authServerUrl: any, data: any) {
    try {
      const config = getRestConfig('json', data.transactionId, '');
      const result = await fetch(authServerUrl + '/v1/confirm', {
        method: 'POST',
        headers: config.headers,
        body: JSON.stringify(data),
      });
      if (!result.ok) {
        const errResponse = await result.json();
        const err: ApiError = new ApiError(errResponse.message, errResponse);
        throw err;
      }
      return result;
    } catch (e) {
      throw e;
    }
  },

  async insertDefaultUserMapping(
    authServerUrl: string,
    userId: string,
    transactionId: string
  ) {
    try {
      const config = getRestConfig('json', transactionId, '');
      const result = await fetch(authServerUrl + '/v1/userMappings', {
        method: 'POST',
        headers: config.headers,
        body: JSON.stringify({ userId: userId }),
      });
      if (!result.ok) {
        const errResponse = await result.json();
        const err: ApiError = new ApiError(errResponse.message, errResponse);
        throw err;
      }
      return result;
    } catch (e) {
      throw e;
    }
  },

  async changePassword(authServerUrl: any, data: any) {
    try {
      const config = getRestConfig('json', uuid(), '');
      const result = await fetch(authServerUrl + '/v1/user/userName/password', {
        method: 'PUT',
        headers: config.headers,
        body: JSON.stringify(data),
      });
      if (!result.ok) {
        const errResponse = await result.json();
        const err: ApiError = new ApiError(errResponse.message, errResponse);
        throw err;
      }
      return result;
    } catch (e) {
      throw e;
    }
  },
  async resendVerificationCode(authServerUrl: any, data: any) {
    try {
      const config = getRestConfig('json', uuid(), '');
      const result = await fetch(authServerUrl + '/v1/user/code/resend', {
        method: 'PUT',
        headers: config.headers,
        body: JSON.stringify(data),
      });
      if (!result.ok) {
        const errResponse = await result.json();
        const err: ApiError = new ApiError(errResponse.message, errResponse);
        throw err;
      }
      return result.json();
    } catch (e) {
      throw e;
    }
  },
};

const api = isServerEnv ? apiServer : apiDesktop;
export default api;
