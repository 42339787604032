import {
  put,
  all,
  fork,
  takeLatest,
  takeEvery,
  call,
  select,
} from 'redux-saga/effects';
import {
  setFormData,
  setToggleMessages,
  SAVE_ATTACHMENT,
  CHANGE_ATTACHMENT_TYPE,
  REMOVE_ATTACHMENT,
  setChartData,
  UPLOAD_ATTACHMENT,
  DELETE_ATTACHMENT,
  FETCH_ATTACHMENT,
  DOWNLOAD_ATTACHMENT,
  DOWNLOAD_ALL_ATTACHMENTS,
  REORDER_ATTACHMENTS,
  SHOW_UPLOAD_VALIDATION
} from '../../actions';
import * as base from '../base';
import CardData from '../../models/carddata.model';
import { UpsertMessage } from '../../constants/appeng.enum';

const getCard = (state: any) => state.appState.cardsData;

export default function* attachmentSaga() {
  yield all([
    fork(watchSaveAttachment),
    fork(watchChangeAttachmentType),
    fork(watchUploadAttachment),
    fork(watchDeleteAttachment),
    fork(watchRemoveAttachment),
    fork(watchFetchAttachment),
    fork(watchDownloadAttachment),
    fork(watchDownloadAllAttachment),
    fork(watchReorderAttachments),
    fork(watchShowUploadValidation),
  ]);
}

function* watchSaveAttachment() {
  yield takeEvery(SAVE_ATTACHMENT, saveAttachmentDetails);
}

function* watchChangeAttachmentType() {
  yield takeEvery(CHANGE_ATTACHMENT_TYPE, changeAttachmentType);
}

function* watchReorderAttachments() {
  yield takeEvery(REORDER_ATTACHMENTS, reOrderAttachments);
}

function* watchShowUploadValidation() {
  yield takeEvery(SHOW_UPLOAD_VALIDATION, showUploadValidation)
}

function* watchUploadAttachment() {
  yield takeEvery(UPLOAD_ATTACHMENT, uploadAttachmentSaga);
}

function* watchDeleteAttachment() {
  yield takeEvery(DELETE_ATTACHMENT, deleteAttachmentSaga);
}

function* watchRemoveAttachment() {
  yield takeEvery(REMOVE_ATTACHMENT, removeAttachment);
}

function* watchFetchAttachment() {
  yield takeEvery(FETCH_ATTACHMENT, fetchAttachment);
}

function* watchDownloadAttachment() {
  yield takeEvery(DOWNLOAD_ATTACHMENT, downloadAttachment);
}

function* watchDownloadAllAttachment() {
  yield takeEvery(DOWNLOAD_ALL_ATTACHMENTS, downloadAllAttachments);
}

function* saveAttachmentDetails(action: any): Generator<any, any, any> {
  return yield call(
    base.saveAttachmentDetails,
    action.attachmentData,
    action.parentId
  );
}

function* changeAttachmentType(action: any): Generator<any, any, any> {
  let existingCardDataForLoading = yield select(getCard);
  existingCardDataForLoading[action.formId].isPreprocessorRunning = true;
  existingCardDataForLoading[action.formId].reloadAttachmentFiles = true;
  yield put(
    setChartData(existingCardDataForLoading[action.formId], action.formId)
  );

  let existingCardData = yield select(getCard);
  if (existingCardData[action.formId].data.attachmentData) {
    let data: any = {};
    let count = 0;
    let isAvailable = false;
    for (let attachment of existingCardData[action.formId].data
      .attachmentData) {
      if (
        attachment.attachment[0].attachmentId == action.data ||
        (attachment.attachment[0].fileName == action.data &&
          attachment.attachment[0].ATTACHMENT_MODE == 'uploaded')
      ) {
        data = attachment;
        existingCardData[action.formId].data.attachmentData[
          count
        ].attachment[0].info5 = action.attachmentType;

        if (attachment.attachment[0].attachmentId == action.data) {
          isAvailable = true;
        } else if (action.parentId) {
          existingCardData[action.formId].data.attachmentData[
            count
          ].attachment[0].ATTACHMENT_MODE = 'saved';
        }
        break;
      }
      count++;
    }
    if (isAvailable) {
      yield call(base.changeAttachmentType, action.data, action.attachmentType);
    } else if (action.parentId) {
      yield call(base.saveAttachmentDetails, [data], action.parentId);
    }

    existingCardData[action.formId].attachmentTypeError = false;
    existingCardData[action.formId].isPreprocessorRunning = false;
    existingCardData[action.formId].reloadAttachmentFiles = false;
    yield put(setChartData(existingCardData[action.formId], action.formId));
  }
}

function* reOrderAttachments(action: any): Generator<any, any, any> {
  try{
    let existingCardDataForLoading = yield select(getCard);
    existingCardDataForLoading[action.cardId].isPreprocessorRunning = true;
    yield put(setChartData(existingCardDataForLoading[action.cardId], action.cardId));

    yield call(base.reOrderAttachments, action.fileData);

    let existingCardData = yield select(getCard);
    existingCardData[action.cardId].isPreprocessorRunning = false;
    yield put(setChartData(existingCardData[action.cardId], action.cardId));

    const messages: string[] = [];
    messages.push(UpsertMessage.REORDER_SUCCESSFUL);
    yield put(setToggleMessages(messages));
  } catch (e) {
    const messages: string[] = [];
    messages.push(UpsertMessage.UNSUCCESSFUL);
    yield put(setToggleMessages(messages));
  }
}

function* showUploadValidation(action: any): Generator<any, any, any> {
  if(action.message && UpsertMessage[action.message]){
    const messages: string[] = [];
    messages.push(UpsertMessage[action.message]);
    yield put(setToggleMessages(messages));
  }
}

function* uploadAttachmentSaga(action: any): Generator<any, any, any> {
  const messages: string[] = [];
  if (action.data.size <= 5242880 && !action.data.name.endsWith('.exe')) {
    let existingCardData1 = yield select(getCard);
    existingCardData1[action.formId].isPreprocessorRunning = true;
    existingCardData1[action.formId].reloadAttachmentFiles = true;
    yield put(setChartData(existingCardData1[action.formId], action.formId));

    let result;

    try {
      if (
        !action.attachmentDetails.isAttachTypeApplicable &&
        action.attachmentDetails.parentPrimaryKey
      ) {
        result = yield call(
          base.uploadAttachmentToPermanent,
          action.attachmentDetails,
          action.parentId,
          action.data
        );
        result.attachment[0]['ATTACHMENT_MODE'] = 'saved';
      } else {
        result = yield call(
          base.uploadAttachment,
          action.attachmentDetails,
          action.parentId,
          action.data
        );
        result.attachment[0]['ATTACHMENT_MODE'] = 'uploaded';
      }
      if (result.error) {
        const messages: any = [];
        messages.push(result.error);
        yield put(setToggleMessages(messages));
      } else {
        result.attachment[0]['APPEND_DETAILS'] =
          action.attachmentDetails.appendDetails;

        let existingCardData = yield select(getCard);
        let updatedData: any = existingCardData[action.formId].data
          .attachmentData
          ? [...existingCardData[action.formId].data.attachmentData]
          : [];
        updatedData.push(result);
        existingCardData[action.formId].data.attachmentData = updatedData;

        existingCardData[action.formId].attachmentTypeError = false;
        existingCardData[action.formId].isPreprocessorRunning = false;
        existingCardData1[action.formId].reloadAttachmentFiles =
          !existingCardData1[action.formId].reloadAttachmentFiles;
        existingCardData[action.formId].isAttachTypeApplicable =
          action.attachmentDetails.isAttachTypeApplicable;
        yield put(setChartData(existingCardData[action.formId], action.formId));
      }
    } catch (e) {
      console.log(e);
      messages.push(UpsertMessage.UNSUCCESSFUL);
      yield put(setToggleMessages(messages));
      let existingCardData2 = yield select(getCard);
      existingCardData2[action.formId].attachmentTypeError = false;
      existingCardData2[action.formId].isPreprocessorRunning = false;
      existingCardData2[action.formId].reloadAttachmentFiles =
        !existingCardData1[action.formId].reloadAttachmentFiles;
      existingCardData2[action.formId].isAttachTypeApplicable =
        action.attachmentDetails.isAttachTypeApplicable;
      yield put(setChartData(existingCardData2[action.formId], action.formId));
    }
  } else if (action.data.name.endsWith('.exe')) {
    messages.push(UpsertMessage.EXE_FILES_NOT_ALLOWED);
    yield put(setToggleMessages(messages));
  } else {
    messages.push(UpsertMessage.FILE_SIZE_EXCEEDED);
    yield put(setToggleMessages(messages));
  }
}

function* deleteAttachmentSaga(action: any): Generator<any, any, any> {
  let existingCardData = yield select(getCard);
  const userSessionData = base.getUserDetail();
  const user = userSessionData.APP_LOGGED_IN_USER_ID;
  let data: any = {};
  let count = 0;
  let isAvailable = false;
  for (const attachment of existingCardData[action.formId].data
    .attachmentData) {
    if (attachment.attachment[0].attachmentId === action.id) {
      data = attachment;
      existingCardData[action.formId].data.attachmentData.splice(count, 1);
      isAvailable = true;
      break;
    }
    count++;
  }
  if (isAvailable) {
    yield call(base.deleteAttachment, action.id, user, action.parentId, [data]);
  }
  yield put(setChartData(existingCardData[action.formId], action.formId));
}

function* removeAttachment(action: any): Generator<any, any, any> {
  let existingCardData = yield select(getCard);
  if (existingCardData[action.formId].data.attachmentData) {
    let data: any = {};
    let count = 0;
    let isAvailable = false;
    for (let attachment of existingCardData[action.formId].data
      .attachmentData) {
      if (
        attachment.attachment[0].attachmentId == action.data ||
        attachment.attachment[0].fileName == action.data
      ) {
        data = attachment;
        existingCardData[action.formId].data.attachmentData.splice(count, 1);
        isAvailable = true;
        break;
      }
      count++;
    }
    if (isAvailable) {
      yield call(base.removeAttachment, [data]);
    }
    yield put(setChartData(existingCardData[action.formId], action.formId));
  }
}

function* fetchAttachment(action: any): Generator<any, any, any> {
  const response = yield call(
    base.fetchAttachment,
    action.parentId,
    action.entityName
  );
  let attachmentsList: any = [];
  response.map((attachmentData: any) => {
    attachmentData['ATTACHMENT_MODE'] = 'saved';
    let attachment_Data: any = [];
    attachment_Data.push(attachmentData);
    const attachmentMap: any = { attachment: attachment_Data };
    attachmentsList.push(attachmentMap);
  });
  let existingCardsData = yield select(getCard);
  const cardsData = { ...existingCardsData };
  const currentCardData = cardsData[action.referralId];
  let cardData = new CardData(
    action.referralId,
    currentCardData.type,
    { ...currentCardData.data, attachmentData: attachmentsList },
    currentCardData.portalId,
    currentCardData.parentId,
    currentCardData.referenceData,
    currentCardData.errorData,
    currentCardData.options,
    currentCardData.datagridErrorData,
    currentCardData.toggleTabRefresh,
    currentCardData.editableGrid,
    currentCardData.workflowActions,
    currentCardData.tabGroupId,
    currentCardData.entityName,
    currentCardData.tabPortalId,
    currentCardData.entityId,
    currentCardData.parentCardId,
    false,
    '',
    false,
    currentCardData.isAttachTypeApplicable
  );
  yield put(setFormData(cardData, action.referralId, false, false, false));
}

function* downloadAttachment(action: any): Generator<any, any, any> {
  if (action.cardId) {
    let existingCardDataForLoading = yield select(getCard);
    existingCardDataForLoading[action.cardId].isPreprocessorRunning = true;
    yield put(
      setChartData(existingCardDataForLoading[action.cardId], action.cardId)
    );
  }
  try {
    const response = yield call(
      base.downloadAttachment,
      action.id,
      action.name
    );
    if (response && !response.ok) {
      if (response.status === 404) {
        const result = response;
        generateDowloadAttachmentLink(action.data, action.name);
      } else {
        const messages: string[] = [];
        messages.push('File is not present. Please upload again.');
        yield put(setToggleMessages(messages));
      }
    } else {
      response.blob().then((blob: any) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = action.name;
        a.click();
      });
    }
  } catch (e) {
    console.log(e);
    const messages: string[] = [];
    messages.push(UpsertMessage.UNSUCCESSFUL);
    yield put(setToggleMessages(messages));
  }

  if (action.cardId) {
    let existingCardData = yield select(getCard);
    existingCardData[action.cardId].isPreprocessorRunning = false;
    yield put(setChartData(existingCardData[action.cardId], action.cardId));
  }
}

function* downloadAllAttachments(action: any) {
  let existingCardDataForLoading = yield select(getCard);
  existingCardDataForLoading[action.cardId].isPreprocessorRunning = true;
  yield put(
    setChartData(existingCardDataForLoading[action.cardId], action.cardId)
  );

  const messages: string[] = [];
  try {
    const response = yield call(base.downloadAllAttachments, action.fileData);
    response.blob().then((blob) => {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.href = url;
      a.download = 'Attachments.zip';
      a.click();
    });
  } catch (e) {
    console.log(e);
    messages.push(UpsertMessage.UNSUCCESSFUL);
    yield put(setToggleMessages(messages));
  }
  let existingCardData = yield select(getCard);
  existingCardData[action.cardId].isPreprocessorRunning = false;
  yield put(setChartData(existingCardData[action.cardId], action.cardId));
}

function generateDowloadAttachmentLink(data: any, name: any) {
  const url = window.URL.createObjectURL(data);
  const link = document.createElement('a');
  link.href = url;
  link.download = name;
  link.click();
  window.URL.revokeObjectURL(url);
}
