import api from '../api';
import { call } from 'redux-saga/effects';
import { getPrivilege, containsKey } from '../utils/ClientUtils';
import { CompositeEntityNode } from '../api/metadata-query/CompositeEntityNode';
import { MenuGroup } from '../api/metadata-query/MenuGroup';
import { PortalCard } from '../api/metadata-mutation/PortalCard';
import { CompositeEntity } from '../api/metadata-query/CompositeEntity';
import { RootNode } from '../api/metadata-query/RootNode';
import { ChildNodesInTree } from '../api/metadata-query/ChildNodesInTree';
import { ReportForm } from '../api/metadata-query/ReportForm';
import { Portal } from '../api/metadata-query/Portal';
import { PortalDataGrid } from '../api/metadata-query/PortalDataGrid';
import { PortalFilterForm } from '../api/metadata-query/PortalFilterForm';
import { ConfigItemByType } from '../api/metadata-query/ConfigItemTypeQuery';
import { PortalForm } from '../api/metadata-query/PortalForm';
import { FormQuery } from '../api/metadata-query/FormQuery';
import { createStorage } from '../storage';
import cloneDeep from 'lodash/cloneDeep';
import { Widget } from '../api/metadata-query/Widget';
import { isValidPhoneNumber } from 'react-phone-number-input';
import {
  NOPRIVILEGE,
  VIEW,
  APP_LOGGED_IN_ROLE_ID,
  USER_SESSION_LOCAL_STORAGE_KEY,
  APP_LOGGED_IN_YEAR,
  QueryType,
  DataType,
  NUMBER_REGEX,
  EMAIL_REGEX,
  DECIMAL_REGEX,
  ALPHABETS_ONLY_REGEX,
  AUTH_TOKEN,
  RECORD_INDEX,
} from '../constants/appeng.enum';
import { Languages } from '../api/metadata-query/Languages';
import { RefreshToken } from 'info-auth-client';
import ReportOptions from '../models/report.options.model';
import { userDetailsJwtKey } from '../api/index';
import { FormField } from '../api/metadata-query/FormFieldQuery';
import { LogicalEntity } from '../api/metadata-query/LogicalEntityQueryForName';
import { isConfigObjectAccessible } from '../composite-entity/form/CardGenerators';
import { LogicalEntityOperationQuery } from '../api/metadata-query/LogicalEntityOperation';
import { DataGrid } from '../api/metadata-query/DataGrid';
import { jwtDecode } from 'jwt-decode';

import { Applications } from '../api/metadata-query/Application';
import { Roles } from '../api/metadata-query/Role';
import { isExpressionResolved } from '../utils/ClientUtils';
import { v4 as uuid } from 'uuid';
import { client } from '../utils/ClientUtils';
import { any } from 'prop-types';

let graphqlquery: any;
if (window.env.REACT_APP_PLATFORM == 'desktop') {
}

export function* authJwtKey(): Generator<any, any, any> {
  try {
    const storage = createStorage();
    const auth = storage.getItem(AUTH_TOKEN);
    let decodedToken: any = jwtDecode(auth);
    let currentDate = new Date();

    if (decodedToken.exp * 1000 - 1000000 < currentDate.getTime()) {
      yield RefreshToken(window.env.REACT_APP_AUTH_DETAILS!);
      const currentSession = storage.getItem('REFRESH_AUTH_TOKEN');
      let refreshDecodedToken: any = jwtDecode(currentSession);
      if (
        refreshDecodedToken &&
        currentSession &&
        auth !== currentSession &&
        decodedToken.exp < refreshDecodedToken.exp
      ) {
        storage.setItem(AUTH_TOKEN, currentSession);
        storage.removeItem('REFRESH_AUTH_TOKEN');
        return currentSession;
      } else {
        return auth;
      }
    } else {
      return auth;
    }
  } catch (e) {}
}

export function* applicationRoleMicrosoftAd(): Generator<any, any, any> {
  const response = yield call(api.applicationRoleMicrosoftAd);
  return response;
}

export function* executeActionFlow(
  flowId: string,
  data: any
): Generator<any, any, any> {
  const authToken = yield call(authJwtKey);
  return yield call(api.executeActionFlow, flowId, data, authToken);
}
export function* getMyProcessInstanceData(): Generator<any, any, any> {
  const authToken = yield call(authJwtKey);
  const response = yield call(api.getMyProcessInstanceData, authToken);
  return response;
}
export function* getMyTaskData(filter: any): Generator<any, any, any> {
  const authToken = yield call(authJwtKey);
  const response = yield call(api.getMyTaskData, authToken, filter);
  return response;
}

export function* getPossibleWorkflowActions(
  taskId: string
): Generator<any, any, any> {
  const authToken = yield call(authJwtKey);
  const response = yield call(
    api.getPossibleWorkflowActions,
    authToken,
    taskId
  );
  return response;
}

export function* getSendFeedbackData(): Generator<any, any, any> {
  const authToken = yield call(authJwtKey);
  const response = yield call(api.getSendFeedbackData, authToken);
  return response;
}
export function* getProjectOption(userId: any): Generator<any, any, any> {
  const authToken = yield call(authJwtKey);
  const response = yield call(api.getProjectOption, userId, authToken);
  return response;
}

export function* getDefaultProjectAndRoleFromUserId(
  userId: any,
  projectId: any
): Generator<any, any, any> {
  const authToken = yield call(authJwtKey);
  const response = yield call(
    api.getDefaultProjectAndRoleFromUserId,
    userId,
    projectId,
    authToken
  );
  return response;
}

export function* getOktaUserInfo(): Generator<any, any, any> {
  const authToken = yield call(authJwtKey);
  const response = yield call(api.getOktaUserInfo, 'Bearer ' + authToken);
  return response;
}

export function* getRoleOption(userId: any): Generator<any, any, any> {
  const authToken = yield call(authJwtKey);
  const response = yield call(api.getRoleOption, userId, authToken);
  return response;
}

export function* getAllRoleOption(): Generator<any, any, any> {
  const authToken = yield call(authJwtKey);
  const response = yield call(api.getAllRoleOption, authToken);
  return response;
}

export function* getAllApplications(): Generator<any, any, any> {
  const authToken = yield call(authJwtKey);
  const response = yield call(api.getProjects, authToken);
  return response;
}

export function* getApplicationById(
  id: string | number
): Generator<any, any, any> {
  const authToken = yield call(authJwtKey);
  const response = yield call(api.getProjectByProjectId, id, authToken);
  return response;
}

export function* downloadReport(
  requestBody: ReportOptions
): Generator<any, any, any> {
  const authToken = yield call(authJwtKey);
  return yield call(api.downloadReport, requestBody, authToken);
}
export function* saveAttachmentDetails(
  attachmentData: any,
  parentId: any
): Generator<any, any, any> {
  const authToken = yield call(authJwtKey);
  yield call(
    api.saveAttachmentDetails,
    { attachment: attachmentData, parentId: parentId },
    authToken
  );
}
export function* changeAttachmentType(
  attachmentId: string,
  attachmentType: string
): Generator<any, any, any> {
  const authToken = yield call(authJwtKey);
  yield call(
    api.changeAttachmentType,
    { attachmentId, attachmentType },
    authToken
  );
}
export function* reOrderAttachments(fileData: any){
  const authToken = yield call(authJwtKey);
  yield call(api.reOrderAttachments, {fileData}, authToken);
}
export function* removeAttachment(
  attachmentData: any
): Generator<any, any, any> {
  const authToken = yield call(authJwtKey);
  yield call(api.removeAttachment, { attachment: attachmentData }, authToken);
}

export function* upsertEntityRecord(
  requestBody: any
): Generator<any, any, any> {
  const transactionId = uuid();
  const authToken = yield call(authJwtKey);
  return yield call(
    api.upsertEntityRecord,
    requestBody,
    authToken,
    transactionId
  );
}

export function* upsertFormRecord(
  requestBody: any,
  logicalEntityIdOrName: string,
  primaryKey: string | undefined
): Generator<any, any, any> {
  const transactionId = uuid();
  const authToken = yield call(authJwtKey);
  if (primaryKey) {
    return yield call(
      api.updateForm,
      requestBody,
      authToken,
      transactionId,
      logicalEntityIdOrName,
      primaryKey
    );
  } else {
    return yield call(
      api.insertForm,
      requestBody,
      authToken,
      transactionId,
      logicalEntityIdOrName
    );
  }
}

export function* getGridData(
  gridId: any,
  data: any,
  isServerPaginationEnable: boolean,
  page?: number,
  limit?: number
): Generator<any, any, any> {
  const authToken = yield call(authJwtKey);
  const response = yield call(
    api.getGridData,
    gridId,
    Object.assign(data, getUserDetail()),
    authToken,
    isServerPaginationEnable,
    page,
    limit
  );
  return response;
}

export function* getFormData(
  gridId: any,
  primaryKey: any,
  referenceData: any
): Generator<any, any, any> {
  const authToken = yield call(authJwtKey);
  const response = yield call(
    api.getFormData,
    gridId,
    primaryKey,
    authToken,
    referenceData
  );
  return response;
}

export function* getEntityData(
  entityId: any,
  primaryKey: any
): Generator<any, any, any> {
  const authToken = yield call(authJwtKey);
  const response = yield call(
    api.getEntityData,
    entityId,
    primaryKey,
    authToken
  );
  return response;
}

export function* getFormDataUsingLEId(
  logicalEntityId: any,
  primaryKey: any
): Generator<any, any, any> {
  const authToken = yield call(authJwtKey);
  const response = yield call(
    api.getFormDataUsingLEId,
    logicalEntityId,
    primaryKey,
    authToken
  );
  return response;
}

export function* getChartData(
  chartId: any,
  data: any
): Generator<any, any, any> {
  const authToken = yield call(authJwtKey);
  const response = yield call(api.getChartData, chartId, data, authToken);
  return response;
}

export function* getCombinedChildFormData(
  entityName: any,
  data: any
): Generator<any, any, any> {
  const authToken = yield call(authJwtKey);
  const response = yield call(
    api.getCombinedChildFormData,
    entityName,
    data,
    authToken
  );
  return response;
}

export function* getOptionsData(
  formFieldId: any,
  data: any
): Generator<any, any, any> {
  const authToken = yield call(authJwtKey);
  const response = yield call(api.getOptionsData, formFieldId, data, authToken);
  return response;
}

export function* getAllOptionsData(
  data: any,
  parentId: any,
  occuranceNumber: any
): Generator<any, any, any> {
  const authToken = yield call(authJwtKey);
  const response = yield call(
    api.getAllOptionsData,
    data,
    parentId,
    occuranceNumber,
    authToken
  );
  return response;
}

export function* getOptionsDataInBulk(
  formFieldId: any,
  data: any,
  occuranceNumber: any,
  parentFormId: any,
  updatedOption: any
): Generator<any, any, any> {
  const authToken = yield call(authJwtKey);
  const response = yield call(api.getOptionsData, formFieldId, data, authToken);
  const key = parentFormId + '_' + occuranceNumber + '_' + formFieldId;
  updatedOption[key] = response;
}

export const getCurrentRole = () => {
  const usersession = getUserDetail();
  return String(usersession[APP_LOGGED_IN_ROLE_ID]).split(',');
};

export const getCurrentFinacialYear = () => {
  const usersession = getUserDetail();
  return usersession[APP_LOGGED_IN_YEAR];
};

export const getUserDetail = () => {
  const storage = createStorage();
  const userProfile = storage.getItem(USER_SESSION_LOCAL_STORAGE_KEY);
  const userProfileMap = JSON.parse(userProfile ? userProfile : '{}');
  const usersession = cloneDeep(userProfileMap);
  delete usersession.AdditionalDetails;
  usersession.APP_CURRENT_DATE = new Date();
  if (
    userProfileMap.AdditionalDetails &&
    userProfileMap.AdditionalDetails.UserContext
  ) {
    Object.assign(usersession, userProfileMap.AdditionalDetails.UserContext);
  } else {
    Object.assign(usersession, userProfileMap.AdditionalDetails);
  }
  return usersession;
};

export const getDefaultDataMap = (form: any) => {
  let defaultDataMap: any = {};
  const privilegeForm = getPrivilege(form.privileges, getCurrentRole());
  if (privilegeForm !== NOPRIVILEGE) {
    if (
      form &&
      form.defaultDataString !== null &&
      form.defaultDataString !== undefined &&
      form.defaultDataString !== ''
    ) {
      form.defaultDataString.split('~').map((data: any) => {
        data.split(',').map((element: any) => {
          defaultDataMap[element.split(':')[0]] = element.split(':')[1];
          return null;
        });
        return null;
      });
    }
  }
  return Object.assign(defaultDataMap, getUserDetail());
};

export const getDefaultFFMap = (
  formFields: any,
  formData: any,
  refrenceData?: any
) => {
  const valueMap: any = {};
  formFields.map((ff: any) => {
    if (ff.logicalColumn || ff.dbCode) {
      const privilegeFF = getPrivilege(ff.privileges, getCurrentRole());
      const dbCode = ff.logicalColumn ? ff.logicalColumn.dbCode : ff.dbCode;
      if (privilegeFF !== NOPRIVILEGE) {
        if (
          ff.defaultValue &&
          ff.defaultValue !== '' &&
          ff.defaultValue !== null &&
          !formData[dbCode]
        ) {
          valueMap[dbCode] = ff.defaultValue;
        } else {
          if (!formData[dbCode]) {
            valueMap[dbCode] =
              refrenceData &&
              Object.keys(refrenceData).length > 0 &&
              refrenceData[dbCode]
                ? refrenceData[dbCode]
                : null;
          }
        }

        if (ff.type === 'DatePicker') {
          if (
            ff.defaultValue === 'currentDate' ||
            (privilegeFF === VIEW && ff.isMandatory)
          ) {
            valueMap[dbCode] = new Date();
          }
        }
      }
    }
    return null;
  });
  return valueMap;
};

export const getFormId = (node: any, mode: any) => {
  return mode === 'Edit'
    ? node.editForm
      ? node.editForm.configObjectId
      : node.insertForm.configObjectId
    : node.insertForm
      ? node.insertForm.configObjectId
      : node.editForm.configObjectId;
};

export const getFFListThatNeedOptions = (
  formSections: any,
  formData: any,
  resetFFList: any,
  parentFormId: any,
  formId: any,
  occuranceNumber: any
) => {
  let FFListNeedOption: string[] = [];
  formSections.map((formSection: any) => {
    formSection.formFields.map((ff: any) => {
      const dbCode = ff.logicalColumn ? ff.logicalColumn.dbCode : ff.dbCode;
      if (resetFFList.includes(ff.configObjectId)) {
        if (parentFormId && formId && parentFormId !== formId) {
          formData[0]['child'][formId][occuranceNumber][dbCode] = null;
        } else {
          formData[0][dbCode] = null;
        }
        if (ff.type === 'SelectOption' || ff.type === 'MultiSelect') {
          if (!ff.multivalueList) {
            FFListNeedOption.push(
              ff.configObjectId + ':' + ff.placeHolder + ':' + dbCode
            );
          }
        }
      }
      return null;
    });
    return null;
  });
  return FFListNeedOption;
};

export const updateFormData = (
  parentFormId: any,
  formId: any,
  existingCardData: any,
  formData: any,
  dbCode: any,
  value: any,
  occuranceNumber: any,
  referralId: any
) => {
  if (
    parentFormId &&
    existingCardData[referralId] &&
    formId !== existingCardData[referralId].id
  ) {
    Object.keys(formData[0].child).map((childFormId) => {
      if (formId === childFormId) {
        formData[0].child[childFormId][occuranceNumber][dbCode] = value;
      }
      return null;
    });
  } else {
    formData[0][dbCode] = value;
  }
};

export const getFormSections = (
  data: any,
  mode: any,
  parentFormId: any,
  formId: any
) => {
  if (parentFormId && data.CompositeEntityNode.combinedNodes) {
    let formSections;
    for (let i = 0; i < data.CompositeEntityNode.combinedNodes.length; i++) {
      let childNode = data.CompositeEntityNode.combinedNodes[i];
      if (!formSections) {
        if (mode !== 'Edit') {
          formSections =
            childNode.insertForm &&
            childNode.insertForm.configObjectId === formId
              ? childNode.insertForm.formSections
              : childNode.editForm &&
                  childNode.editForm.configObjectId === formId
                ? childNode.editForm.formSections
                : undefined;
        } else {
          formSections =
            childNode.editForm && childNode.editForm.configObjectId === formId
              ? childNode.editForm.formSections
              : childNode.insertForm &&
                  childNode.insertForm.configObjectId === formId
                ? childNode.insertForm.formSections
                : undefined;
        }
      }
    }
    return formSections;
  } else {
    if (mode !== 'Edit') {
      return data.CompositeEntityNode.insertForm
        ? data.CompositeEntityNode.insertForm.formSections
        : data.CompositeEntityNode.editForm.formSections;
    } else {
      return data.CompositeEntityNode.editForm
        ? data.CompositeEntityNode.editForm.formSections
        : data.CompositeEntityNode.insertForm.formSections;
    }
  }
};

export const getLogicalEntity = (data: any, parentFormId: any, formId: any) => {
  if (parentFormId && data.CompositeEntityNode.combinedNodes) {
    for (let i = 0; i < data.CompositeEntityNode.combinedNodes.length; i++) {
      let childNode = data.CompositeEntityNode.combinedNodes[i];
      const entity =
        childNode.insertForm && childNode.insertForm.configObjectId === formId
          ? childNode.entity
          : childNode.editForm && childNode.editForm.configObjectId === formId
            ? childNode.entity
            : undefined;
      if (entity) {
        return entity;
      }
    }
  } else {
    return data.CompositeEntityNode.entity;
  }
};

export const getForm = (node: any, mode: any) => {
  return mode === 'Edit'
    ? node.editForm
      ? node.editForm
      : node.insertForm
    : node.insertForm
      ? node.insertForm
      : node.editForm;
};

export function* uploadAttachment(
  attachmentDetails: any,
  parentId: any,
  data: any
): Generator<any, any, any> {
  const authToken = yield call(authJwtKey);
  var formData = new FormData();
  formData.append('files', data);
  formData.append('entityName', attachmentDetails.entityName);
  formData.append('keyName', attachmentDetails.keyName);
  formData.append('parentId', parentId);
  formData.append('description', attachmentDetails.appendDetails);
  let response;
  try {
    response = yield call(api.uploadAttachment, formData, authToken);
  } catch (e) {
    console.log('e', e);
    response = { error: 'Please check the file Size or extension.' };
  }
  return response;
}
export function* uploadAttachmentToPermanent(
  attachmentDetails: any,
  parentId: any,
  data: any
): Generator<any, any, any> {
  const authToken = yield call(authJwtKey);
  var formData = new FormData();
  formData.append('files', data);
  formData.append('entityName', attachmentDetails.entityName);
  formData.append('keyName', attachmentDetails.keyName);
  formData.append('parentId', parentId);
  formData.append('description', attachmentDetails.appendDetails);
  let response;
  try {
    response = yield call(api.uploadAttachmentToPermanent, formData, authToken);
  } catch (e) {
    console.log('e', e);
    response = { error: 'Please check the file Size or extension.' };
  }
  return response;
}
export function* updatePortalCardData(params: any): Generator<any, any, any> {
  const isServerEnv = window.env.REACT_APP_PLATFORM !== 'desktop';
  if (isServerEnv) {
    const mutation: any = PortalCard;
    return yield client.mutate({
      mutation,
      variables: { input: params },
    });
  }
}
export function* fetchAttachment(
  parentId: any,
  entityName: any
): Generator<any, any, any> {
  const authToken = yield call(authJwtKey);
  const response = yield call(
    api.fetchAttachment,
    parentId,
    entityName,
    authToken
  );

  return response;
}
export function* downloadAttachment(
  id: any,
  name: any
): Generator<any, any, any> {
  const authToken = yield call(authJwtKey);
  const response = yield call(api.downloadAttachment, id, name, authToken);
  return response;
}
export function* downloadAllAttachments(fileData) {
  const authToken = yield call(authJwtKey);
  const response = yield call(api.downloadAllAttachments, fileData, authToken);
  return response;
}
export function* getConfigData(
  queryType: any,
  id: any
): Generator<any, any, any> {
  id = id.includes('_') ? id.split('_')[1] : id;
  const isServerEnv = window.env.REACT_APP_PLATFORM !== 'desktop';
  let query: any;
  switch (queryType) {
    case 'NodeQuery':
      query = CompositeEntityNode;
      break;
    case 'MenuQuery':
      query = MenuGroup;
      break;
    case 'CEQuery':
      query = CompositeEntity;
      break;
    case 'RootNodeQuery':
      query = RootNode;
      break;
    case 'ChildNodeInTreeQuery':
      query = ChildNodesInTree;
      break;
    case 'ReportFormQuery':
      query = ReportForm;
      break;
    case 'Languages':
      query = Languages;
      break;
    case 'PortalQuery':
      query = Portal;
      break;
    case 'WidgetQuery':
      query = Widget;
      break;
    case 'PortalDataGridQuery':
      query = PortalDataGrid;
      break;
    case 'PortalFilterFormQuery':
      query = PortalFilterForm;
      break;
    case 'PortalFormQuery':
      query = PortalForm;
      break;
    case 'FormQuery':
      query = FormQuery;
      break;
    case 'ConfitItemType':
      query = ConfigItemByType;
      break;
    case QueryType.FORMFIELD:
      query = FormField;
      break;
    case QueryType.LOGICAL_ENTITY:
      query = LogicalEntity;
      break;
    case 'LogicalEntityOperation':
      query = LogicalEntityOperationQuery;
      break;
    case 'DataGridQuery':
      query = DataGrid;
      break;
    case 'ApplicationQuery':
      query = Applications;
      break;
    case 'RoleQuery':
      query = Roles;
      break;
  }
  if (isServerEnv) {
    const authToken = yield call(authJwtKey);
    const userDetails = yield call(userDetailsJwtKey);
    if (queryType !== 'ConfitItemType') {
      return yield client.query({
        query: query,
        variables: { id: id },
        context: {
          headers: {
            authorization: authToken,
            userDetails: userDetails,
            provider: window.env.REACT_APP_AUTH_DETAILS!.provider.type,
          },
        },
        fetchPolicy: 'cache-first',
      });
    } else {
      return yield client.query({
        query: query,
        variables: { type: id },
        context: {
          headers: {
            authorization: authToken,
            userDetails: userDetails,
            provider: window.env.REACT_APP_AUTH_DETAILS!.provider.type,
          },
        },
        fetchPolicy: 'cache-first',
      });
    }
  } else {
    query = query.loc.source.body;
    query = query.trim();
    query = query.substring(query.indexOf('{'));
    query = query.substring(0, query.length);
    query = query.replace('$id', '"' + id + '"');
    return yield graphqlquery(query);
  }
}

export const getValueAsObjectForSelectOption = (
  initialvalue: any,
  selectedOptions: any
) => {
  let value = null;
  if (selectedOptions) {
    selectedOptions.map((object: any) => {
      if (String(object.id) === String(initialvalue)) {
        value = object;
      }
      return null;
    });
  }
  return value;
};

export const didUpdateRequired = (
  parentFormId: any,
  formId: any,
  existingCardData: any,
  formData: any,
  dbCode: any,
  value: any,
  occuranceNumber: any,
  referralId: any
) => {
  let doUpdate = false;

  if (
    parentFormId &&
    existingCardData[referralId] &&
    formId !== existingCardData[referralId].id
  ) {
    Object.keys(formData[0].child).map((childFormId) => {
      if (formId === childFormId) {
        if (formData[0].child[childFormId][occuranceNumber][dbCode] !== value) {
          doUpdate = true;
        }
      }
      return null;
    });
  } else {
    if (formData[0][dbCode] !== value) {
      doUpdate = true;
    }
  }
  return doUpdate;
};

export const validateCompositeEntityRecord = (
  data: any,
  logicalEntity: any,
  mode: any,
  errorMap: any,
  parentKey: any,
  form: any,
  formLeavelMessage: any
) => {
  let errorMapKey: string = parentKey
    ? parentKey +
      '=>0=>' +
      logicalEntity.configObjectId +
      '=>' +
      data[RECORD_INDEX] +
      '=>'
    : logicalEntity.configObjectId + '=>0=>';
  let isErrorOccured: boolean = false;
  let hiddenFeildDbCode: any = [];

  form.formSections.map((formSection: any, index: any) => {
    formSection.formFields.map((formFieldMap: any, index: any) => {
      if (formFieldMap.type === 'Hiddenfield') {
        hiddenFeildDbCode.push(formFieldMap.logicalColumn.dbCode);
      }
    });
  });
  logicalEntity.logicalColumns.map((logicalColumn: any, index: any) => {
    let componentErrorMessage = '';
    if (
      logicalColumn.dataType === 'PHONE_NUMBER' &&
      data[logicalColumn.dbCode]
    ) {
      componentErrorMessage = isValidPhoneNumber(data[logicalColumn.dbCode])
        ? ''
        : 'Invalid Phone Number';
    }
    let mandatoryError: any = clientSideMandatoryValidation(
      data[logicalColumn.dbCode],
      logicalColumn
    );
    let regexTypeError: any = clientSideRegexValidation(
      data[logicalColumn.dbCode],
      logicalColumn,
      data,
      mode
    );
    if (mandatoryError || regexTypeError || componentErrorMessage) {
      isErrorOccured = true;
      let mandatoryErrorMessage = mandatoryError ? mandatoryError : '';
      let regexTypeErrorMessage = regexTypeError ? regexTypeError : '';
      if (hiddenFeildDbCode.includes(logicalColumn.dbCode)) {
        formLeavelMessage =
          formLeavelMessage +
          ' ' +
          logicalColumn.dbCode +
          ' ' +
          mandatoryErrorMessage +
          regexTypeErrorMessage +
          componentErrorMessage;
      }
      errorMap.formFieldValidation[errorMapKey + logicalColumn.dbCode] =
        mandatoryErrorMessage + regexTypeErrorMessage + componentErrorMessage;
    }
  });
  if (formLeavelMessage) {
    errorMap['formLeavelMessage'] = formLeavelMessage;
  }
  return errorMap;
};

export const clientSideLenghtValidation = (value: any, logicalColumn: any) => {
  if (value && logicalColumn.length && value.length > logicalColumn.length) {
    return 'Invalid Length. Max length is upto ' + logicalColumn.length;
  }
};

export const clientSideMandatoryValidation = (
  value: any,
  logicalColumn: any
) => {
  if (
    logicalColumn.isMandatory &&
    (value === undefined ||
      value === null ||
      value === '' ||
      (value && value.toString().trim() === ''))
  ) {
    return 'Is Required. ';
  }
};

export const clientSideDataTypeValidation = (
  value: any,
  logicalColumn: any
) => {
  switch (logicalColumn.dataType) {
    case DataType.NUMBER:
      if (!NUMBER_REGEX.test(value)) {
        return 'Invalid Number. ';
      }
      break;
    case DataType.EMAIL:
      if (
        !(
          value === null ||
          value === undefined ||
          value === '' ||
          EMAIL_REGEX.test(value)
        )
      ) {
        return 'Invalid Email Address Format. ';
      }
      break;
    case DataType.DECIMAL:
      if (!DECIMAL_REGEX.test(value)) {
        return 'Invalid Value.Please enter a valid number.';
      }
      break;
    case DataType.ALPHABET:
      if (
        !(
          value === null ||
          value === undefined ||
          value === '' ||
          ALPHABETS_ONLY_REGEX.test(value)
        )
      ) {
        return 'Allowed alphabets only. ';
      }
      break;
  }
};

export const clientSideRegexValidation = (
  value: any,
  logicalColumn: any,
  dataMap: any,
  mode: any
) => {
  if (
    logicalColumn.standardValidations &&
    logicalColumn.standardValidations.length > 0
  ) {
    let messageList: any = [];
    for (const validation of logicalColumn.standardValidations) {
      if (
        !validation.mode ||
        validation.mode === 'BOTH' ||
        validation.mode === mode
      ) {
        if (
          !validation.isConditionAvailable ||
          (dataMap &&
            validation.conditionExpression &&
            isConfigObjectAccessible(validation.conditionExpression, dataMap))
        ) {
          if (
            !validation.regex.startsWith('JAVASCRIPT$$') &&
            !validation.regex.startsWith('#{')
          ) {
            let validationOBj = new RegExp(validation.regex);
            let valueDef = value ? value : null;
            if (!validationOBj.test(valueDef)) {
              messageList.push(validation.defaultErrorMessage);
            }
          } else {
            let test = isExpressionResolved(validation.regex, dataMap);
            if (!test) {
              messageList.push(validation.defaultErrorMessage);
            }
          }
        }
      }
    }
    return messageList.join(' ');
  }
};

export const updateFFWhichHasOneSelectedOption = (
  options: any,
  FFListNeedOption: any,
  parentFormIdForKey: any,
  occuranceNumber: any,
  formData: any,
  formId: any,
  dbCode: any,
  placeHolder: any,
  parentFormId: any
) => {
  FFListNeedOption.map((ffId: any) => {
    const formFeildId = ffId.split(':')[0];
    const ffPlaceHolder = ffId.split(':')[1];
    const ffDbCode = ffId.split(':')[2];
    const key = parentFormIdForKey + '_' + occuranceNumber + '_' + formFeildId;
    const selectedOptions = options[key];
    const optionAsList = generateoptionsInFormat(selectedOptions);
    let initialvalue: any;
    if (parentFormId) {
      initialvalue = formData[0].child[formId][occuranceNumber][ffDbCode];
    } else {
      initialvalue = formData[0][ffDbCode];
    }
    const shouldAddInFormData: boolean =
      !initialvalue &&
      optionAsList &&
      (!ffPlaceHolder || ffPlaceHolder === 'null') &&
      optionAsList.length === 1
        ? true
        : false;

    if (shouldAddInFormData) {
      const updatedValue = optionAsList[0]['id'];
      if (String(updatedValue) !== String(initialvalue)) {
        if (parentFormId) {
          formData[0].child[occuranceNumber][ffDbCode] = updatedValue;
        } else {
          formData[0][ffDbCode] = updatedValue;
        }
      }
    }
    return null;
  });
};

export const generateoptionsInFormat = (selectedOptions: any) => {
  const optionsList = selectedOptions.map((option: any) => {
    const optionObject = {
      value: option[containsKey(option, 'id')],
      label: option[containsKey(option, 'label')],
      id: option[containsKey(option, 'id')],
    };
    Object.assign(option, optionObject);
    return option;
  });
  return optionsList;
};

export const getDefaultFormData = (data: any, refrenceData?: any) => {
  let formData = {
    '0': {
      child: {},
    },
  };
  const parentForm = data.Form;

  let defaultParentFormData = getDefaultDataMap(parentForm);
  Object.assign(formData[0], defaultParentFormData);

  parentForm.formSections.map((formSection: any) => {
    if (formSection.formFields)
      Object.assign(
        formData[0],
        getDefaultFFMap(formSection.formFields, formData[0], refrenceData)
      );
    return null;
  });
  return formData;
};

export const setChildDefaultValues = (
  data: any,
  formId: any,
  formData: any,
  newKey: any,
  formDataRepObject: any
) => {
  data.CompositeEntityNode.combinedNodes.map((childNode: any) => {
    if (
      childNode.addToParentDisplay &&
      (childNode.addToParentInsertForm || childNode.addToParentEditForm)
    ) {
      const childForm = getForm(childNode, 'Insert');
      const childFormId = childForm.configObjectId;
      if (
        childFormId === formId &&
        (!childForm.maxRepeatation ||
          (childForm.maxRepeatation &&
            childForm.maxRepeatation >
              Object.keys(formData[0].child[formId]).length))
      ) {
        formData[0].child[formId][newKey] = formDataRepObject;
        let childDefaultFormData = getDefaultDataMap(childForm);
        Object.assign(formData[0].child[formId][newKey], childDefaultFormData);

        childForm.formSections.map((formSection: any) => {
          Object.assign(
            formData[0].child[formId][newKey],
            getDefaultFFMap(
              formSection.formFields,
              formData[0].child[formId][newKey]
            )
          );
          return null;
        });
        return null;
      }
    }
    return null;
  });
};

export function* updateInlineRecord(
  primaryKey: any,
  logicalEntityId: any,
  data: AnalyserOptions
): Generator<any, any, any> {
  const transactionId = uuid();
  const authToken = yield call(authJwtKey);
  return yield call(
    api.updateInlineRecord,
    primaryKey,
    logicalEntityId,
    data,
    authToken,
    transactionId
  );
}

export function* deleteRecord(
  primaryKey: any,
  logicalEntityIdOrName: any,
  transactionName: any
): Generator<any, any, any> {
  const transactionId = uuid();
  const authToken = yield call(authJwtKey);
  return yield call(
    api.deleteRecord,
    primaryKey,
    logicalEntityIdOrName,
    authToken,
    transactionId,
    transactionName
  );
}

export function* getConfiguarationStructure(
  applicationId: any,
  rootItemId: any
): Generator<any, any, any> {
  const authToken = yield call(authJwtKey);
  const response = yield call(
    api.getConfiguarationStructure,
    authToken,
    applicationId,
    rootItemId
  );
  return response;
}

export function* deleteAttachment(
  id: any,
  user: any,
  parentId: any,
  data: any
): Generator<any, any, any> {
  const authToken = yield call(authJwtKey);
  yield call(api.deleteAttachment, id, user, parentId, data, authToken);
}

export function* deployMeta(): Generator<any, any, any> {
  const authToken = yield call(authJwtKey);
  const response = yield call(api.deployMeta, authToken);
  return response;
}

export const formatChartResponse = (data: any) => {
  if (data && data.columns && data.rows) {
    const columns: string[] = data.columns;
    const records: any[] = [];

    data.rows.map((row: any) => {
      const record: any = {};
      for (const [i, value] of row.entries()) {
        record[columns[i]] = value;
      }
      records.push(record);
      return null;
    });
    return records;
  }
  return data ? data : null;
};

export function* getUserApplicationSpecialAttributes(
  userId: any,
  applicationId: any
): Generator<any, any, any> {
  const authToken = yield call(authJwtKey);
  const response = yield call(
    api.getUserApplicationSpecialAttributes,
    userId,
    applicationId,
    authToken
  );
  return response;
}

export function* getMappingDataFromMaster(
  datasource: any,
  tabeName: any,
  columnKeyName: any,
  columnValueName: any,
  filterValue: any,
  condition: string
): Generator<any, any, any> {
  const authToken = yield call(authJwtKey);
  const response = yield call(
    api.getMappingDataFromMaster,
    datasource,
    tabeName,
    columnKeyName,
    columnValueName,
    filterValue,
    condition,
    authToken
  );
  return response;
}

export function* getDataViaQuery(
  datasource: any,
  query: any
): Generator<any, any, any> {
  const authToken = yield call(authJwtKey);
  const response = yield call(
    api.getDataViaQuery,
    datasource,
    query,
    authToken
  );
  return response;
}

export function* getConfigItem(itemId: string): Generator<any, any, any> {
  const authToken = yield call(authJwtKey);
  const response = yield call(api.getConfigItem, itemId, authToken);
  return response;
}

export function* fetchApplicationLogo(
  parentId: any,
  entityName: any,
  datasource: any
): Generator<any, any, any> {
  const authToken = yield call(authJwtKey);
  const response = yield call(
    api.fetchApplicationLogo,
    parentId,
    entityName,
    datasource,
    authToken
  );
  return response;
}
