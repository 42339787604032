import DOMPurify from 'dompurify';
import { jwtVerify } from 'jose';
import jwkToPem from 'jwk-to-pem';

export interface ClaimVerifyResult {
  readonly userName: string;
  readonly clientId: string;
  readonly isValid: boolean;
  readonly error?: any;
}

interface TokenHeader {
  kid: string;
  alg: string;
}

interface PublicKey {
  alg: string;
  e: string;
  kid: string;
  kty: string;
  n: string;
  use: string;
}

interface PublicKeyMeta {
  instance: PublicKey;
  pem: string;
}

interface PublicKeys {
  keys: PublicKey[];
}

interface MapOfKidToPublicKey {
  [key: string]: PublicKeyMeta;
}

interface Claim {
  token_use: string;
  auth_time: number;
  iss: string;
  exp: number;
  username: string;
  client_id: string;
}
export const restHttp = async (url: string, config: any) => {
  const sanitizedURL = DOMPurify.sanitize(url);
  return await fetch(sanitizedURL, config)
    .then((response) => response.json())
    .then((body) => {
      return body;
    });
};
let cacheKeys: MapOfKidToPublicKey | any;
const getPublicKeys = async (): Promise<MapOfKidToPublicKey> => {
  if (!cacheKeys) {
    const url =
      window.env.REACT_APP_AUTH_DETAILS!.provider.aws.issuer +
      '/.well-known/jwks.json';
    const publicKeys = await restHttp(url, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
    cacheKeys = publicKeys.data.keys.reduce((agg: any, current: any) => {
      const pem = jwkToPem(current);
      agg[current.kid] = { instance: current, pem };
      return agg;
    }, {} as MapOfKidToPublicKey);
    return cacheKeys;
  } else {
    return cacheKeys;
  }
};

const verifyJWT = async (token: any, secretOrPublicKey: any) => {
  try {
    const decoded = jwtVerify(token, secretOrPublicKey);
    return decoded;
  } catch (error) {
    throw new Error('Invalid JWT');
  }
};

const tokenHandler = async (token: string): Promise<ClaimVerifyResult> => {
  const tokenSections = (token || '').split('.');
  if (tokenSections.length < 2) {
    throw new Error('requested token is invalid');
  }
  const headerJSON = atob(tokenSections[0]);
  const header = JSON.parse(headerJSON) as TokenHeader;
  const keys = await getPublicKeys();
  const key = keys[header.kid];
  if (key === undefined) {
    throw new Error('claim made for unknown kid');
  }
  const claim: any = await verifyJWT(token, key.pem);
  const currentSeconds = Math.floor(new Date().valueOf() / 1000);
  if (currentSeconds > claim.exp || currentSeconds < claim.auth_time) {
    throw new Error('claim is expired or invalid');
  }
  if (claim.iss !== window.env.REACT_APP_AUTH_DETAILS!.provider.aws.issuer) {
    throw new Error('claim issuer is invalid');
  }
  if (claim.token_use !== 'access') {
    throw new Error('claim use is not access');
  }
  const result: ClaimVerifyResult = {
    userName: claim.username,
    clientId: claim.client_id,
    isValid: true,
  };
  return result;
};
export { tokenHandler };
