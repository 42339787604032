import gql from 'graphql-tag';

export const PortalCard = gql`
  query PortalCard($id: ID!) {
    PortalCard(id: $id) {
      configObjectId
      name
      configObjectType
      label
      reloadRequired
      archived
      favorite
      editabilityRegex
      x
      y
      w
      responsiveLayout
      displayHeader
      boxShadow
      cardDataSharing
      referDataFromPortal
      h
      isStatic
      type
      uiComponentId
      isAccessibile
      accessibilityRegex
      referenceData
      initialComponent
      isFullScreenRequired
      privileges {
        privilegeId
        privilegeType
        itemId
        roleId
        createdBy
        isDeleted
        insert_ts
        updatedBy
        update_ts
        deletionDate
      }
      childRelations {
        relationType
        parentItemId
        childItemId
      }
    }
  }
`;
