import * as React from 'react';
import { lazy, Suspense } from 'react';
import { App as Auth, InfoAuthConfig } from 'info-auth-client';
import {
  createHashRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
} from 'react-router-dom';

import { fetchAuthData } from '../custom-hooks/useFetchInfoAuth';
import { createStorage } from '../storage';
import SkeletonCore from '../components/SkeletonCore';
const PortalWrapper = lazy(() => import('../new-portal-layout/PortalWrapper'));
const CertificateContainer = lazy(
  () => import('../components/CertificateContainer')
);
const LogOut = lazy(() => import('../components/LogOut'));
const PrivateRoutes = lazy(() => import('./PrivateRoutes'));

// InfoAuthConfig.configure(window.env.REACT_APP_AUTH_DETAILS!);
if (window.env && window.env.REACT_APP_AUTH_DETAILS) {
  console.log('window.env', window.env);
  console.log(
    'window.env.REACT_APP_AUTH_DETAILS',
    window.env.REACT_APP_AUTH_DETAILS
  );
  try {
    const authDetails = window.env.REACT_APP_AUTH_DETAILS;
    InfoAuthConfig.configure(authDetails);
  } catch (error) {
    console.error('Error parsing REACT_APP_AUTH_DETAILS:', error);
  }
} else {
  console.error('window.env or REACT_APP_AUTH_DETAILS is not set correctly.');
}

const loadingComponent = () => {
  return <SkeletonCore count={1} />;
};

const storage = createStorage();

export const router = createHashRouter(
  createRoutesFromElements(
    <>
      <Route
        path="app/ce/"
        element={
          <Suspense fallback={loadingComponent()}>
            <PrivateRoutes />
          </Suspense>
        }
      >
        <Route
          path="portal/:dummyid"
          element={
            <Suspense fallback={loadingComponent()}>
              <PortalWrapper />
            </Suspense>
          }
        />
        <Route
          path="pdfcertificate"
          element={
            <Suspense fallback={loadingComponent()}>
              <CertificateContainer />
            </Suspense>
          }
        />
      </Route>
      <Route
        path="app/logout"
        element={
          <Suspense fallback={loadingComponent()}>
            <LogOut />
          </Suspense>
        }
      />
      <Route
        path="app/treeStructure/:treeType/:compositeEntityKey"
        Component={() => <></>}
      />
      <Route
        path="/*"
        loader={async ({ params }: any) => {
          let path = storage.getItem('USER_GIVEN_PATH');
          const checkAuth = await fetchAuthData();
          return { checkAuth, path };
        }}
        element={
          <Suspense fallback={loadingComponent()}>
            <Auth />
          </Suspense>
        }
      />
    </>
  )
);

const MainRoutes: React.FC = () => {
  return (
    <Suspense fallback={loadingComponent()}>
      <RouterProvider router={router} />
    </Suspense>
  );
};

export default MainRoutes;
