import gql from 'graphql-tag';

export const Portal = gql`
  query Portal($id: ID!) {
    Portal(id: $id) {
      configObjectId
      name
      layoutType
      configObjectType
      portalType
      archived
      favorite
      portalDataSharing
      portalStyle

      helpItems {
        type
        helpURL
        configObjectId
        name
        configObjectType
        privileges {
          privilegeId
          privilegeType
          itemId
          roleId
          createdBy
          isDeleted
          insert_ts
          updatedBy
          update_ts
          deletionDate
        }
      }

      buttonPanels {
        configObjectId
        defaultType
        buttonPanelPosition
        mode
        name
        projectId
        configObjectType
        privileges {
          privilegeId
          privilegeType
          itemId
          roleId
        }
        menuButtons {
          defaultType
          menuLabel
          position
          buttonClass
          warningMessage
          order
          accessibilityRegex
          editabilityRegex
          expressionAvailable
          transactionName
          modalRequired
          portalId
          configObjectId
          name
          configObjectType
          privileges {
            privilegeId
            privilegeType
            itemId
            roleId
          }

          buttons {
            label
            order
            buttonClass
            warningMessage
            portalId
            buttonAlignment
            accessibilityRegex
            editabilityRegex
            expressionAvailable
            modalRequired
            configObjectId
            name
            configObjectType
            projectId
            modalCss
            transactionName
            childRelations {
              relationType
              parentItemId
              childItemId
            }
            privileges {
              privilegeId
              privilegeType
              itemId
              roleId
            }
          }
        }
        buttons {
          label
          order
          buttonClass
          warningMessage
          portalId
          buttonAlignment
          accessibilityRegex
          editabilityRegex
          expressionAvailable
          configObjectId
          name
          configObjectType
          projectId
          modalCss
          transactionName
          childRelations {
            relationType
            parentItemId
            childItemId
          }
          privileges {
            privilegeId
            privilegeType
            itemId
            roleId
          }
        }
      }

      portalCards {
        editabilityRegex
        responsiveLayout
        cardStyle
        cardBodyStyle
        cardHeaderStyle
        configObjectId
        name
        configObjectType
        label
        reloadRequired
        archived
        cardDataSharing
        referDataFromPortal
        favorite
        x
        displayHeader
        boxShadow
        y
        w
        h
        order
        isStatic
        type
        uiComponentId
        isAccessibile
        accessibilityRegex
        referenceData
        initialComponent
        isFullScreenRequired
        privileges {
          privilegeId
          privilegeType
          itemId
          roleId
          createdBy
          isDeleted
          insert_ts
          updatedBy
          update_ts
          deletionDate
        }
        childRelations {
          relationType
          parentItemId
          childItemId
        }
        buttonPanels {
          configObjectId
          defaultType
          buttonPanelPosition
          mode
          privileges {
            privilegeId
            privilegeType
            itemId
            roleId
            createdBy
            isDeleted
            insert_ts
            updatedBy
            update_ts
            deletionDate
          }
          name
          configObjectType
          itemDescription
          projectId
          buttons {
            label
            order
            buttonClass
            warningMessage
            modalRequired
            buttonAlignment
            portalId
            modalCss
            transactionName
            privileges {
              privilegeId
              privilegeType
              itemId
              roleId
              createdBy
              isDeleted
              insert_ts
              updatedBy
              update_ts
              deletionDate
            }
            accessibilityRegex
            editabilityRegex
            expressionAvailable
            configObjectId
            name
            configObjectType
            projectId
          }
        }
        tabGroups {
          configObjectId
          name
          type
          configObjectType
          tabPortals {
            configObjectId
            name
            configObjectType
            order
            tabName
            isDefaultOpen
            expressionAvailable
            accessibilityRegex
            buttonPanels {
              configObjectId
              defaultType
              buttonPanelPosition
              mode
              name
              configObjectType
              itemDescription
              projectId
              privileges {
                privilegeId
                privilegeType
                itemId
                roleId
                createdBy
                isDeleted
                insert_ts
                updatedBy
                update_ts
                deletionDate
              }
              buttons {
                label
                order
                buttonClass
                warningMessage
                modalRequired
                buttonAlignment
                portalId
                modalCss
                transactionName
                privileges {
                  privilegeId
                  privilegeType
                  itemId
                  roleId
                  createdBy
                  isDeleted
                  insert_ts
                  updatedBy
                  update_ts
                  deletionDate
                }
                accessibilityRegex
                editabilityRegex
                expressionAvailable
                configObjectId
                name
                configObjectType
                projectId
              }
              subPortals {
                configObjectId
                name
                configObjectType
                portalType
                archived
                favorite
                modalRequired
                layoutType
                subPortalType
                portalDataSharing
                portalStyle
                tabGroup
                order
                accessibilityRegex
                portalCards {
                  editabilityRegex
                  cardStyle
                  cardBodyStyle
                  cardHeaderStyle
                  configObjectId
                  name
                  configObjectType
                  label
                  reloadRequired
                  archived
                  favorite
                  x
                  displayHeader
                  boxShadow
                  y
                  w
                  h
                  order
                  isStatic
                  type
                  uiComponentId
                  isAccessibile
                  accessibilityRegex
                  referenceData
                  initialComponent
                  isFullScreenRequired
                  privileges {
                    privilegeId
                    privilegeType
                    itemId
                    roleId
                    createdBy
                    isDeleted
                    insert_ts
                    updatedBy
                    update_ts
                    deletionDate
                  }
                }
              }
            }
            subPortals {
              configObjectId
              name
              configObjectType
              portalType
              archived
              favorite
              modalRequired
              subPortalType
              layoutType
              portalDataSharing
              portalStyle
              tabGroup
              order
              accessibilityRegex
            }
          }
        }
        subPortals {
          configObjectId
          name
          configObjectType
          portalType
          archived
          favorite
          modalRequired
          subPortalType
          layoutType
          portalDataSharing
          portalStyle
          tabGroup
          order
          accessibilityRegex
        }
      }
    }
  }
`;
