import { put, all, fork, takeEvery, call, select } from 'redux-saga/effects';
import * as base from '../base';
import { CardData } from '../../models';
import {
  setFormData,
  setToggleMessages,
  UPSERT_ENTITY_RECORD,
  closeModals,
  setStore,
  getGridData,
  getFormData,
  addModalReload,
  setWarningMessages,
} from '../../actions';
import { UpsertMessage, CardType, NodeType } from '../../constants/appeng.enum';
import { router } from '../../routes/Routes';
import cloneDeep from 'lodash/cloneDeep';
import {
  createRequestBody,
  validateCompositeEntityRecord,
} from '../utilitySags';
import { v4 as uuid } from 'uuid';

const getCard = (state: any) => state.appState.cardsData;
const getToggleMessages = (state: any) => state.appState.toggleMessages;
const getOpenModals = (state: any) => state.appState.openModals;

export default function* upsertEntityRecord() {
  yield all([fork(watchUpsertEntityRecord)]);
}

function* watchUpsertEntityRecord() {
  yield takeEvery(UPSERT_ENTITY_RECORD, upsertEntityRecordSaga);
}

function* upsertEntityRecordSaga(action: any): Generator<any, any, any> {
  try {
    const toggleMessages = yield select(getToggleMessages);
    if (toggleMessages.length) yield put(setToggleMessages([]));
    const { data } = yield call(base.getConfigData, 'NodeQuery', action.nodeId);
    const node = cloneDeep(data.CompositeEntityNode);
    const formId = base.getFormId(node, action.mode);
    const requestBody = yield createRequestBody(
      action,
      node,
      action.referralId ? action.referralId : formId
    );

    yield call(
      validateCompositeEntityRecord,
      requestBody,
      data.CompositeEntityNode,
      action.mode,
      action.referralId
    );
    const existingCardData = yield select(getCard);
    if (
      Object.keys(existingCardData[action.referralId].errorData).length === 0 ||
      (existingCardData[action.referralId].errorData &&
        Object.keys(
          existingCardData[action.referralId].errorData.errorData
            .formFieldValidation
        ).length === 0)
    ) {
      const response = yield call(base.upsertEntityRecord, requestBody);

      const existingOpenedModals = yield select(getOpenModals);
      if (!response.errors) {
        const messages: string[] = [];
        if (
          response.mode === 'Insert' &&
          Object.keys(existingOpenedModals).length === 0
        ) {
          messages.push(
            response.message ? response.message : UpsertMessage.SAVESUCCESSFUL
          );
          if (action.modalRequired) {
            const existingCardData = yield select(getCard);
            yield put(closeModals(0, formId));
            yield put(
              getGridData(
                node.dataGrid.configObjectId,
                action.referenceData ? action.referenceData : {},
                action.referralId,
                true,
                existingCardData[action.referralId].parentId,
                existingCardData[action.referralId].portalId
              )
            );
          }
          if (!action.modalRequired && !action.portalForm) {
            if (action.parentId) {
              const layoutData = {
                nodeId: action.nodeId,
                ceId: action.ceId,
                componentType:
                  action.nodeType === NodeType.PARENTNODE
                    ? 'ParentGrid'
                    : 'ChildGrid',
                gridId: node.dataGrid.configObjectId,
                data: action.referenceData,
                referenceData: action.referenceData,
              };
              const existingCardData = yield select(getCard);
              const formData = existingCardData[action.referralId].data;
              if (!action.usePortalReferenceData) {
                const cardData = new CardData(
                  action.referralId,
                  CardType.FORM,
                  formData,
                  action.portalId,
                  action.parentId,
                  undefined,
                  {},
                  existingCardData[action.referralId].options
                );

                const routerParam: any = {};
                Object.assign(routerParam, action.location.state);
                routerParam['portalId'] = action.portalId
                  ? action.portalId
                  : action.parentId;
                routerParam['parentId'] = action.parentId;
                routerParam['referralId'] = action.referralId;
                routerParam[action.referralId] = {
                  AE_RELOAD: {
                    ID: uuid(),
                    TS: new Date().getTime(),
                    STATUS: 'R',
                  },
                  resetReferenceData: true,
                };

                router.navigate(-1);
              } else {
                const cardData = new CardData(
                  action.referralId,
                  CardType.FORM,
                  formData,
                  action.portalId,
                  action.parentId,
                  layoutData,
                  {},
                  existingCardData[action.referralId].options
                );
                const previousData = existingCardData[action.parentId]
                  ? existingCardData[action.parentId].referenceData
                  : {};
                Object.assign(previousData, layoutData);
                const portalData = new CardData(
                  action.parentId,
                  CardType.PORTAL,
                  {},
                  action.portalId,
                  action.parentId,
                  previousData
                );

                const routerParam: any = {};
                Object.assign(routerParam, action.location.state);
                routerParam['portalId'] = action.portalId
                  ? action.portalId
                  : action.parentId;
                routerParam['parentId'] = action.parentId;
                routerParam['referralId'] = action.referralId;
                routerParam[action.referralId] = layoutData;
                routerParam[action.parentId] = previousData;
                Object.assign(routerParam[action.parentId], {
                  resetReferenceData: true,
                });
                yield router.navigate('/app/ce/portal/' + uuid(), {
                  state: routerParam,
                });
              }
            } else {
              const routerParam: any = {};
              Object.assign(routerParam, action.location.state);
              routerParam['portalId'] = action.portalId
                ? action.portalId
                : action.parentId;
              routerParam['parentId'] = action.parentId;
              routerParam['referralId'] = action.referralId;
              routerParam[action.referralId] = {
                AE_RELOAD: {
                  ID: uuid(),
                  TS: new Date().getTime(),
                  STATUS: 'R',
                },
                resetReferenceData: true,
              };
              yield router.navigate('/app/ce/portal/' + uuid(), {
                state: routerParam,
              });
            }
          } else if (
            action.portalForm &&
            Object.keys(existingOpenedModals).length === 0
          ) {
            let existingCardData = yield select(getCard);
            let formData = existingCardData[action.referralId];
            let cardData = new CardData(
              action.referralId,
              CardType.FORM,
              formData.data,
              formData.portalId,
              formData.parentId,
              { INSERT_TRANSACTION_ID: uuid() },
              {},
              formData.options
            );
            yield put(
              setFormData(cardData, action.referralId, false, false, false)
            );
          }
        } else if (Object.keys(existingOpenedModals).length === 0) {
          const existingCardData = yield select(getCard);
          if (response.mode === 'Update') {
            messages.push(
              response.message
                ? response.message
                : UpsertMessage.UPDATESUCCESSFUL
            );
          } else if (response.mode === 'Delete') {
            messages.push(
              response.message
                ? response.message
                : UpsertMessage.DELETESUCCESSFUL
            );
          }
          let pkDBCode =
            existingCardData[action.referralId].referenceData.primaryDBCode;
          let primaryKey = response.pk
            ? response.pk
            : existingCardData[action.referralId].data[0][pkDBCode];

          yield put(
            getFormData(
              action.nodeId,
              action.mode,
              action.nodeType,
              action.ceId,
              primaryKey,
              action.referralId,
              action.resetForm,
              action.location,
              existingCardData[action.referralId].parentId,
              existingCardData[action.referralId].portalId,
              {
                UPDATE_TRANSACTION_ID: uuid(),
                primaryDBCode: pkDBCode,
                actionType: 'UPSERT',
              },
              action.usePortalReferenceData,
              existingCardData[action.referralId].parentCardId
            )
          );
        } else {
          if (response.mode === 'Insert') {
            messages.push(
              response.message ? response.message : UpsertMessage.SAVESUCCESSFUL
            );
          } else if (response.mode === 'Delete') {
            messages.push(
              response.message
                ? response.message
                : UpsertMessage.DELETESUCCESSFUL
            );
          } else {
            messages.push(
              response.message
                ? response.message
                : UpsertMessage.UPDATESUCCESSFUL
            );
          }
        }

        yield put(addModalReload(action.parentId));

        yield put(setToggleMessages(messages));
      } else if (response.errors && response.code === 512) {
        let existingCardData = yield select(getCard);
        const cardsData = cloneDeep(existingCardData);
        const currentCardData = cardsData[action.referralId];
        currentCardData.buttonClicked = '';
        const messages: string[] = [];
        messages.push(response.errors);
        yield all([
          put(setToggleMessages(messages)),
          put(
            setFormData(currentCardData, action.referralId, false, false, false)
          )]);
      } else {
        const messages: string[] = [];
        messages.push(UpsertMessage.VALIDATION_FAILED);
        const appError: any = {
          code: response.code,
          errorData: {
            formFieldValidation: {},
            formValidation: {},
          },
        };
        let warningMessages: string[] = [];
        response.errors.map((err: any) => {
          const lastElement =
            err.location.split('=>')[err.location.split('=>').length - 1];
          if (isNaN(Number(lastElement))) {
            if (err.message) {
              appError.errorData.formFieldValidation[err.location] =
                err.message;
            } else if (err.warningMessage) {
              warningMessages.push(err.warningMessage);
            }
          } else {
            if (err.message) {
              appError.errorData.formValidation[err.location] = err.message;
            } else if (err.warningMessage) {
              warningMessages.push(err.warningMessage);
            }
          }
          return null;
        });
        if (warningMessages.length > 0) {
          let warningMessage: any = {};
          warningMessage[action.referralId] = warningMessages;
          yield put(setWarningMessages(warningMessage));
        }
        let existingCardsData = yield select(getCard);
        const cardsData = cloneDeep(existingCardsData);
        const currentCardData = cardsData[action.referralId];
        currentCardData.errorData = appError;
        currentCardData.buttonClicked = '';
        const updateStore: any = {
          cardsData: cardsData,
          toggleMessages: messages,
        };
        yield put(setStore(updateStore));
      }
    }
  } catch {
    const messages: string[] = [];
    messages.push(UpsertMessage.UNSUCCESSFUL);
    yield put(setToggleMessages(messages));
  }
}
